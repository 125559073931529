<template>
  <div>
    <b-alert
      v-model="showAlert"
      variant="danger"
      style="margin-left: 20px"
      dismissible
    >
      <b>{{ alertMessage }}</b>
    </b-alert>
    <div class="d-flex justify-content-between">
      <div class="d-flex mb-4" style="">
        <div class="avo-page-header-text" style="margin-right: 30px">
          Practices
        </div>
        <div>
          <b-input-group class="search-group">
            <b-input-group-prepend>
              <span class="input-group-text"
                ><b-icon icon="search"></b-icon
              ></span>
            </b-input-group-prepend>
            <b-form-input
              @keyup="debouncePractices"
              @search="debouncePractices"
              class="practice-search-input pl-1"
              type="search"
              v-model="search_params.search"
              placeholder="Try typing a practice's name"
            />
            <b-input-group-append>
              <span class="divider"></span>
              <b-dropdown class="dropdown filter-dropdown" :boundary="'window'">
                <template #button-content>
                  <b-icon-funnel class="mr-2" />{{ filterInputLabel }}
                </template>
                <b-dropdown-form class="d-flex flex-column align-items-start">
                  <span style="font-size: 20px; font-weight: 300">Filters</span>

                  <div class="selecter-label">Provider</div>
                  <multiselect
                    v-model="filters.providers"
                    :hide-selected="true"
                    :options="providers"
                    track-by="provider_id"
                    label="full_name_with_suffix"
                    :multiple="true"
                    :show-labels="false"
                    :close-on-select="false"
                    placeholder="-- Select an Option --"
                  >
                  </multiselect>

                  <div class="selecter-label">Payer</div>
                  <multiselect
                    v-model="filters.payers"
                    :hide-selected="true"
                    :options="payers"
                    track-by="payer_id"
                    label="name"
                    :multiple="true"
                    :show-labels="false"
                    :close-on-select="false"
                    placeholder="-- Select an Option --"
                  >
                  </multiselect>

                  <div class="d-flex justify-content-start mt-4">
                    <b-button
                      @click="doSearch"
                      variant="outline-primary"
                      class="avo-primary-btn mr-5"
                      >Apply</b-button
                    >
                    <b-button
                      @click="clearFilters"
                      variant="outline-primary"
                      class="avo-basic-btn"
                      >Clear Filters</b-button
                    >
                  </div>
                </b-dropdown-form>
              </b-dropdown>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div style="margin-right: 20px; padding-top: 10px">
        <b-button
          v-if="canViewButtons()"
          @click="addPractice"
          variant="outline-primary"
          class="avo-primary-btn"
          >Add Practice</b-button
        >
      </div>
    </div>
    <b-overlay :show="!loaded" variant="white" style="min-height: 200px">
      <div v-if="practices.length > 0">
        <b-row
          v-for="(practice, index) in practices"
          :key="index"
          class="practice-box"
          @click="selectPractice(index)"
        >
          <b-col cols="4" style="margin-top: 10px">
            <div
              class="d-flex flex-column"
              :style="
                'padding-left:10px; border-left:3px solid ' + colors[index % 7]
              "
            >
              <div class="avo-text-light">Practice group</div>
              <div class="practice-name-text">
                {{ practice.name }}
              </div>
            </div>
          </b-col>
          <b-col
            cols="4"
            class="d-flex flex-row"
            style="margin-top: 10px; justify-content: center"
          >
            <div class="d-flex flex-column" style="margin-right: 20px">
              <div class="avo-text-light">Locations</div>
              <div class="bold">
                {{ practice.count_locations }}
              </div>
            </div>
            <div class="d-flex flex-column" style="margin-left: 20px">
              <div class="avo-text-light">Providers</div>
              <div class="bold">
                {{ practice.count_providers }}
              </div>
            </div>
          </b-col>
          <b-col
            v-if="canViewButtons()"
            cols="4"
            class="d-flex justify-content-end"
          >
            <div
              class="d-flex flex-column align-items-right"
              style="margin-right: 20px"
            >
              <div class="avo-text-light">Unassigned Claims</div>
              <div style="color: #41b0a5; text-align: right">
                {{ formatNumber(practice.count_unassigned_claims) }}
              </div>
              <div class="avo-text-light" style="text-align: right">
                {{ formatDollars(practice.total_dollar_unassigned_claims, false) }}
              </div>
            </div>
            <div class="d-flex flex-column" style="margin-left: 20px">
              <div class="avo-text-light">Unpaid Claims</div>
              <div style="color: #eec05a; text-align: right">
                {{ formatNumber(practice.count_unpaid_claims) }}
              </div>
              <div class="avo-text-light" style="text-align: right">
                {{ formatDollars(practice.total_dollar_unpaid_claims, false) }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="page"
              align="center"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-else-if="loaded"
        class="avo-page-header-text"
        style="text-align: center"
      >
        No practices found
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  make_url,
  authenticated_request,
  debounce,
  permissionsMixin,
  alertsMixin,
  formatNumber,
  formatDollars,
} from "../../common.js";
import Multiselect from "vue-multiselect";

export default {
  name: "PracticesList",
  mixins: [permissionsMixin, alertsMixin],
  components: {
    Multiselect,
  },
  data() {
    return {
      practices: [],
      providers: [],
      payers: [],
      filters: {
        providers: [],
        payers: [],
      },
      search_params: {
        search: "",
        provider_ids: "",
        payer_ids: "",
      },

      perPage: 20,
      totalRows: 1,
      page: 1,
      colors: [
        "#41B0A5",
        "#7550CB",
        "#15ACC1",
        "#E68E5D",
        "#FE679F",
        "#67B765",
        "#EEC05A",
      ],
      loaded: false,
    };
  },
  methods: {
    getFilters: function () {
      const url = make_url("/reports/practices/filters");
      const self = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
      })
        .then(function (response) {
          if (response) {
            self.hideError();
            const data = response.data;
            self.providers = data.providers;
            self.payers = data.payers;
            self.$store.commit(
              "practices/setPracticeTypes",
              data.practice_types
            );
            return data || [];
          }
        })
        .catch(function (error) {
          self.showError(error);
        });
      return promise;
    },
    getPractices: function () {
      var params = this.search_params;
      params.per_page = this.perPage;
      params.page = this.page;

      if (!this.hasPermission("see_all_practices")) {
        params.worker_id = localStorage.getItem("worker_id");
      }
      const url = make_url("/reports/practices");
      const self = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
        params: params,
      })
        .then(function (response) {
          if (response) {
            const practices = response.data.practices;
            self.practices = practices;
            self.totalRows = response.data.pagination.total;
            self.perPage = response.data.pagination.per_page;
            self.loaded = true;
            return practices || [];
          }
        })
        .catch(function (error) {
          self.showError(error);
          self.loaded = true;
        });

      return promise;
    },
    getAddress: function (address_id, billing) {
      // TODO: Need to change backend so workers can see practice address
      if (!this.canViewButtons()) return;
      const url = make_url("/addresses/" + address_id).toString();
      const self = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
      })
        .then(function (response) {
          if (response) {
            self.hideError();
            if (billing) {
              self.$store.commit(
                "practices/setSelectedPracticeAddress",
                response.data
              );
            } else {
              self.$store.commit(
                "practices/setSelectedPracticeBillingAddress",
                response.data
              );
            }
          }
        })
        .catch(function (error) {
          self.showError(error);
        });
      return promise;
    },
    doSearch: function () {
      this.getPractices();
      this.$store.commit("practices/setPracticeListNeedsRefresh", false);
    },
    clearFilters: function () {
      this.filters.providers = [];
      this.filters.payers = [];
      this.search_params.provider_ids = "";
      this.search_params.payer_ids = "";
      this.doSearch();
    },
    selectPractice(index) {
      this.$store.commit("practices/selectPractice", this.practices[index]);
      const address_id = this.practices[index].address;
      const billing_address_id = this.practices[index].billing_address;
      if (address_id) {
        this.getAddress(address_id, false);
      } else {
        this.$store.commit("practices/setSelectedPracticeAddress", null);
      }
      if (billing_address_id) {
        this.getAddress(this.practices[index].billing_address, true);
      } else {
        this.$store.commit("practices/setSelectedPracticeBillingAddress", null);
      }
    },
    addPractice() {
      this.$store.commit("practices/setAddPractice", true);
    },

    //error methods
    showError(alertMessage) {
      this.showSuccess = false;
      this.setShowAlert(true, alertMessage);
    },
    hideError() {
      this.setShowAlert(false);
    },
    //permissions
    canViewButtons() {
      return this.hasPermission("see_all_practices");
    },
    formatNumber,
    formatDollars,
  },
  computed: {
    filterCount: function () {
      var count = 0;
      if (this.search_params["provider_ids"].length > 0) {
        count = count + 1;
      }
      if (this.search_params["payer_ids"].length > 0) {
        count = count + 1;
      }
      return count;
    },
    filterInputLabel: function () {
      const count = this.filterCount;
      if (count == 0) {
        return "Filters";
      } else {
        return count + " Filter(s)";
      }
    },
    practiceListNeedsRefresh: function () {
      return this.$store.state.practices.practiceListNeedsRefresh;
    },
  },
  watch: {
    "filters.providers": function (newValues) {
      this.search_params.provider_ids = newValues
        .map((obj) => obj.provider_id)
        .join();
    },
    "filters.payers": function (newValues) {
      this.search_params.payer_ids = newValues
        .map((obj) => obj.payer_id)
        .join();
    },
    page: function () {
      this.doSearch();
    },
    practiceListNeedsRefresh: function (newValue) {
      if (newValue == true) {
        this.doSearch();
      }
    },
  },
  created() {
    this.debouncePractices = debounce(this.getPractices, 900);
  },
  mounted() {
    this.doSearch();
    this.getFilters();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Text styling */
.practice-box {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 20px;
  padding-right: 40px;
  border: 1px solid #c9d2df;
  border-radius: 5px;
  cursor: pointer;
}
.practice-name-text {
  font-weight: 400;
  color: var(--dark);
  font-size: 18px;
}

/* Styling the search bar group */
::v-deep .search-group {
  position: relative;
  margin-right: 20px;
  border: 1px solid #c9d2df;
  border-radius: 5px;
  max-width: 650px;
}
::v-deep .practice-search-input,
.input-group-text {
  height: 50px;
  border: none;
  border-radius: 5px;
  background: white;
  color: #9ca3ad;
}
::v-deep .practice-search-input::placeholder {
  color: #9ca3ad;
  font-size: 12px;
  opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 5px;
  color: #9ca3ad;
  background: white;
  font-size: 12px;
  height: 50px;
  min-width: 150px;
  text-align: left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active,
::v-deep .btn-secondary:not(:disabled):not(.disabled).active,
::v-deep .show > .btn-secondary.dropdown-toggle {
  color: #519eff !important;
  background-color: white;
}
::v-deep .b-dropdown .btn-secondary:focus {
  box-shadow: none !important;
}
.divider {
  display: inline-block;
  width: 0;
  height: 20px;
  margin: auto;
  border-right: 1.8px solid #d2d8e2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
  padding: 20px 30px 30px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
  width: 100.3%;
  right: -1px !important;
  left: auto !important;
  top: 45px !important;
  transform: none !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 16px 48px #1617182b !important;
}
::v-deep .flag-dropdown .dropdown-menu {
  width: 200px;
  padding: 10px;
  left: -1px !important;
  top: 48px !important;
  transform: none !important;
  font-size: 14px;
  font-weight: 500;
  color: #64728c;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 16px 48px #1617182b !important;
}
::v-deep .flag-dropdown .b-dropdown-form {
  padding: 0.2rem 0.8rem;
}
.selecter-label {
  color: #64728c;
  font-size: 14px;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 5px;
}
</style>