<template>
<div v-if="practice">
    <b-alert
        v-model="showAlert"
        variant="danger"
        style="margin-left:20px"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div class="d-flex justify-content-between" style="margin-left:20px">
        <div>
            <b-input-group class="search-group">
                <b-input-group-prepend>
                    <span class="input-group-text"><b-icon icon="search"></b-icon></span>
                </b-input-group-prepend>
                <b-form-input
                    @keyup="debouncePracticeLocations"
                    @search="debouncePracticeLocations"
                    class="practice-search-input pl-1"
                    type="search" v-model="search"
                    placeholder="Search location"/>    
            </b-input-group>
        </div>
        <div style="margin-right:20px">
            <b-button v-if="hasPermission('edit')" v-b-modal.new-practice-location class="avo-primary-btn">Add Location</b-button>
            <practice-location-add @submitted="getPracticeLocations()" />
        </div>
    </div>
    <div v-if="locations.length > 0">
        <div v-for="(location, index) in locations" :key="index" class="location-box">
            <div :style="(selected == index) ? 'padding-bottom:20px;':''">
                <b-row>
                    <b-col cols="auto" @click="selectLocation(index)" class="avo-text-light">
                        Location
                    </b-col>
                    <b-col @click="selectLocation(index)" class="avo-header-text">
                        {{location.name}}
                    </b-col>
                    <b-col lg="auto" v-if="hasPermission('edit')">
                        <b-button v-b-modal="'practice-location-edit-'+location.location_id" class="avo-basic-btn"><i class="fas fa-edit mr-2"></i>Edit</b-button>
                        <practice-location-edit :selected-location="location" @submitted="getPracticeLocations()" />
                    </b-col>
                </b-row>
            </div>
            <div v-if="index == selected" style="border-top:solid 1px #C9D2DF;margin-left:-20px;margin-right:-40px">
                <b-row class="mt-4" style="margin-left:0px">
                    <b-col>
                        <div class="d-flex flex-column">
                            <div class="label-text">
                                NPI
                            </div>
                            <div>
                                {{location.group_npi || "-"}}
                            </div>
                        </div>
                    </b-col>
                    <b-col>
                        <div class="d-flex flex-column">
                            <div class="label-text">
                                Tax ID
                            </div>
                            <div>
                                {{location.tax_id || "-"}}
                            </div>
                        </div>
                    </b-col>
                    <b-col>
                        <!--TODO: only if location is medicare-->
                        <div class="d-flex flex-column">
                            <div class="label-text">
                                PTAN
                            </div>
                            <div>
                                {{location.ptan || "-"}}
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4" style="margin-left:0px">
                    <b-col>
                        <div class="d-flex flex-column">
                            <div class="label-text">
                                Phone
                            </div>
                            <div>
                                {{location.telephone || "-"}}
                            </div>
                        </div>
                    </b-col>
                    <b-col>
                        <div class="d-flex flex-column">
                            <div class="label-text">
                                Fax
                            </div>
                            <div>
                                {{location.fax || "-"}}
                            </div>
                        </div>
                    </b-col>
                    <b-col>
                        <div class="d-flex flex-column">
                            <div class="label-text">
                                Address
                            </div>
                            <div>
                                {{location.address || "-"}}
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
            </div>
            <div>
                <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="page" align="center" />
            </div>
        </div>
    <div v-else-if="loaded" style="text-align:center">
        No locations found for this practice
    </div>
</div>
</template>

<script>
import { make_url, authenticated_request, debounce, permissionsMixin, alertsMixin } from '../../common.js'
//import Multiselect from 'vue-multiselect'
import PracticeLocationAdd from './PracticeLocationAdd.vue'
import PracticeLocationEdit from './PracticeLocationEdit.vue'

export default {
    name: 'PracticeLocations',
    mixins: [permissionsMixin, alertsMixin],
    components: {
        PracticeLocationAdd,
        PracticeLocationEdit,
    },
    data(){
    return {
        locations: [],
        search: "",
        loaded: false,
        selected: -1,
        
        perPage: 20,
        totalRows: 1,
        page: 1,
    }
    },
    methods: {
        getPracticeLocations: function(){
            var params = {
                'search': this.search
            };
            params.per_page = this.perPage;
            params.page = this.page;
            //if(this.sortBy){
            //    params.sort = this.sortBy + ":" + (this.sortDesc?"desc":"asc")
            //}
            const url = make_url("/reports/practices/" + this.practice_id + "/locations");
            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: params
            }).then(function(response){
                if (response) {
                    const locations = response.data.locations;
                    self.locations = locations;
                    self.totalRows = response.data.pagination.total;
                    self.perPage = response.data.pagination.per_page;
                    self.loaded = true;
                    return locations || [];
                }
            }).catch(function(error){
                self.showError(error);
                self.loaded = true;
            });
            
            return promise;
        },
        selectLocation(index){
            if (this.selected == index){
                this.selected = -1;
            }
            else{
                this.selected = index;
            }
        },
        doSearch(){
            console.log("search")
        },
        clearFilters(){
            console.log("clear")
        },
        //error methods
        showError(alertMessage){
            this.showSuccess = false;
            this.setShowAlert(true, alertMessage);
        },
        hideError(){
            this.setShowAlert(false);
        },
    },
    computed: {
        practice: function(){
            return this.$store.state.practices.selected_practice;
        },
        practice_id: function(){
            if (this.practice != null){
                return this.practice.practice_id;
            }
            return null;
        },
        filterCount: function() {
            var count = 0;
            //TODO: count filters when they exist
            return count;
        },
        filterInputLabel: function(){
            const count = this.filterCount;
            if(count == 0) {
                return "Filters";
            } else {
                return count + " Filter(s)";
            }
        },
    },
    watch: {
        page: function() {
            this.getPracticeLocations();
        }
    },
    created(){
        this.getPracticeLocations();
        this.debouncePracticeLocations = debounce(this.getPracticeLocations, 900);
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.location-box {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 20px;
    padding-right: 40px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    cursor: pointer;
}


/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    margin-right: 20px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:650px;
}
::v-deep .practice-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .practice-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
.divider {
    display: inline-block;
    width: 0;
    height: 20px;
    margin: auto;
    border-right: 1.8px solid #D2D8E2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
    padding: 20px 30px 30px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
    width:100.3%;
    right: -1px !important;
    left: auto !important;
    top:45px !important;
    transform: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .flag-dropdown .dropdown-menu {
    width: 200px;
    padding: 10px;
    left: -1px !important;
    top:48px !important;
    transform: none !important;
    font-size: 14px;
    font-weight: 500;
    color: #64728C;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .flag-dropdown .b-dropdown-form {
    padding: 0.2rem 0.8rem;
}
.selecter-label {
    color: #64728C;
    font-size: 14px;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom:5px;
}
</style>