<template>
<div v-if="practice">
    <b-container fluid="xl">
    <b-row>
        <b-col cols="6" style="margin-top:14px">
            <div class="information-card">
                <b-row class="avo-header-text mb-4">
                    <b-col>
                        Practice Information
                    </b-col>
                </b-row>
                <b-row class="information-row" style="margin-top:20px">
                    <b-col cols=3 class="label-text">
                        Name
                    </b-col>
                    <b-col>
                        {{practice.name}}
                    </b-col>
                </b-row>
                <hr>
                <b-row class="information-row">
                    <b-col cols=3 class="label-text">
                        Group NPI
                    </b-col>
                    <b-col>
                        {{practice.group_npi || "-"}}
                    </b-col>
                </b-row>
                <hr>
                <b-row class="information-row">
                    <b-col cols=3 class="label-text">
                        Tax ID
                    </b-col>
                    <b-col>
                        {{practice.tax_id || "-"}}
                    </b-col>
                </b-row>
                <hr>
                <b-row class="information-row">
                    <b-col cols=3 class="label-text">
                        Taxonomy Codes
                    </b-col>
                    <b-col>
                        <practice-taxonomy-codes 
                            :practice="practice" 
                            :taxonomyCodes="practice.taxonomy_codes"
                            @submitted="$emit('practiceUpdated')"
                            />
                    </b-col>
                </b-row>
                <hr>
                <b-row class="information-row">
                    <b-col cols=3 class="label-text">
                        Type
                    </b-col>
                    <b-col v-if="practice.practice_type != null">
                        {{practice_types[practice.practice_type]}}
                    </b-col>
                    <b-col v-else>
                        N/A
                    </b-col>
                </b-row>
                <hr>
                <b-row class="information-row">
                    <b-col cols=3 class="label-text">
                        Address
                    </b-col>
                    <b-col>
                        {{practice_address ? practice_address.full_address || "-" : "-"}}
                    </b-col>
                </b-row>
                <hr>
                <b-row class="information-row">
                    <b-col cols=3 class="label-text">
                        Billing Address
                    </b-col>
                    <b-col>
                        {{practice_billing_address ? practice_billing_address.full_address || "-" : "-"}}
                    </b-col>
                </b-row>
            </div>
        </b-col>
        <b-col cols="6">
            <document-list idType="practice" :id="practice.practice_id" perPage="6" mode="table"/>
        </b-col>
    </b-row>
    </b-container>
</div>
</template>

<script>
import DocumentList from '../documents/DocumentList.vue'
import PracticeTaxonomyCodes from './PracticeTaxonomyCodes.vue'

export default {
    name: 'PracticeDetails',
    components: {
        DocumentList,
        PracticeTaxonomyCodes,
    },
    computed: {
        practice: function(){
            return this.$store.state.practices.selected_practice;
        },
        practice_address: function(){
            return this.$store.state.practices.selected_practice_address;
        },
        practice_billing_address: function(){
            return this.$store.state.practices.selected_practice_billing_address;
        },
        practice_types: function(){
            return this.$store.state.practices.practice_types;
        }
    },
    watch: {
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.information-card{
    padding:10px;
    padding-left:20px;
    padding-right:20px;
    margin:10px;
    margin-left:30px;
    margin-right:30px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    min-height:615px
}
.information-row{
    margin: 5px;
}
</style>