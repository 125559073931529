<template>
<div id="add-practice-location">
<b-modal id="new-practice-location" @hide="resetModal" title="New Practice Location" hide-footer size="xl">
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <b-row>
        <b-col>
            <b-form-group
            id="name"
            label="Name:">
            <b-input-group>
                <b-form-input v-model="form.name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="ptan"
            label="PTAN:">
            <b-input-group>
                <b-form-input v-model="form.ptan"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="telephone"
            label="Phone:">
            <b-input-group>
                <b-form-input v-model="form.telephone"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="fax"
            label="Fax:">
            <b-input-group>
                <b-form-input v-model="form.fax"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="address_1"
            label="Address Line 1:">
            <b-input-group>
                <b-form-input v-model="form.address_line1"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="address_2"
            label="Address Line 2:">
            <b-input-group>
                <b-form-input v-model="form.address_line2"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="city"
            label="City:">
            <b-input-group>
                <b-form-input v-model="form.city"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="state"
            label="State:">
            <b-input-group>
                <b-form-input v-model="form.state"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="country"
            label="Country:">
            <b-input-group>
                <b-form-input v-model="form.country"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="zip"
            label="Zip:">
            <b-input-group>
                <b-form-input v-model="form.zip"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col style="text-align:center">
            <b-button @click="onSubmit" class="avo-primary-btn">
                Create Practice Location
            </b-button>
        </b-col>
    </b-row>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'

export default {
  name: 'PracticeLocationAdd',
  mixins: [alertsMixin],
  data: function(){
      return {
            form: {
                name: null,
                ptan: null,
                telephone: null,
                fax: null,
                address_line1: null,
                address_line2: null,
                city: null,
                state: null,
                country: null,
                zip: null,
            },
            location: {},
      }
  },
  methods: {
    clearData(){
        this.form = {
            name: null,
            ptan: null,
            telephone: null,
            fax: null,
            address_line1: null,
            address_line2: null,
            city: null,
            state: null,
            country: null,
            zip: null,
        }
        this.location = {}
        this.hideError();
    },
    validateInputs(){
        this.hideError();
        
        //validate inputs
        if ((this.form.name) == null || (this.form.name == "")){
            this.showError("Missing field: name");
            return false;
        }
        
        this.location = {
            location: {
                name: this.form.name
            }
        }
        
        if ((this.form.ptan != null) && (this.form.ptan != "")){
            this.location.location.ptan = this.form.ptan;
        }
        
        
        var address_fields = [this.form.telephone, this.form.fax, this.form.address_line1, this.form.address_line2,
            this.form.city, this.form.state, this.form.country, this.form.zip]
        var field_names = ['telephone', 'fax', 'address_line1', 'address_line2', 'city', 'state', 'country', 'zip']
        
        for (var i = 0; i < address_fields.length; i++){
            if ((address_fields[i] != null) && (address_fields[i] != "")){
                if (this.location.address){
                    this.location.address[field_names[i]] = address_fields[i];
                }
                else{
                    this.location.address = {}
                    this.location.address[field_names[i]] = address_fields[i];
                }
            }
        }
        
        return true
    },
    addPracticeLocationWithAddress(){
        const url = make_url("/forms/practices/" + this.practice_id + "/location_with_address");
        const self = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: this.location
        }).then(function(response){
            if (response){
                self.$bvModal.hide('new-practice-location');
                self.$emit('submitted');
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    addPracticeLocation(){
        const url = make_url("/practices/" + this.practice_id + "/locations");
        const self = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: this.location.location
        }).then(function(response){
            if (response){
                self.$bvModal.hide('new-practice-location');
                self.$emit('submitted');
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    onSubmit(){
        if (this.validateInputs()){
            if (this.location.address){
                this.addPracticeLocationWithAddress();
            }
            else{
                this.addPracticeLocation();
            }
        }
    },
    resetModal(){
        this.hideError();
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
    computed: {
        practice_id: function(){
            return this.$store.state.practices.selected_practice.practice_id;
        }
    }
}
</script>

<style scoped>
</style>