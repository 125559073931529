<template>
  <multiselect
    openDirection="bottom"
    placeholder="Search by taxonomy code or name"
    :custom-label="codeLabel"
    v-model="selectedCode"
    track-by="code"
    :options="selectableCodes"
    :loading="codesLoading"
    :internal-search="false"
    :close-on-select="!multiple"
    :multiple="multiple"
    :allow-empty="allowEmpty"
    :show-labels="false"
    @search-change="debounceCodes"
    :showNoOptions="true"
  >
    <template slot="noResult"> No taxonomy codes match your search </template>
    <template slot="noOptions">
      Start typing a code name or address...
    </template>
  </multiselect>
</template>

<script>
import { make_url, authenticated_request, debounce } from "../../common.js";
import Multiselect from "vue-multiselect";

export default {
  name: "TaxonomyCodeMultiselect",
  components: {
    Multiselect,
  },
  props: {
    value: [Object, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      selectableCodes: [],
      selectedCode: null,
      codeSearchText: "",
      codesLoading: false,
    };
  },
  methods: {
    codeSearchChange: function (searchQuery) {
      this.codesLoading = true;
      this.codeSearchText = searchQuery;
      if (searchQuery.length > 0) {
        this.codeAutocomplete();
      } else {
        this.codesLoading = false;
      }
    },
    codeAutocomplete() {
      var vm = this;
      const url = make_url("/reports/taxonomy-codes/autocomplete");
      authenticated_request({
        method: "get",
        url: url,
        params: {
          search: this.codeSearchText,
          limit: 10,
        },
      })
        .then(function (response) {
          vm.codesLoading = false;
          const codes = response.data.codes;
          vm.selectableCodes = codes || [];
          return codes || [];
        })
        .catch(function (errors) {
          console.log(errors.response);
          vm.showError(errors);
        });
    },
    codeLabel(code) {
      if (code.name) {
        return `${code.name} (${code.code})`;
      } else {
        return code.code;
      }
    },
  },
  watch: {
    selectedCode() {
      this.$emit("input", this.selectedCode);
    },
    value: {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.selectedCode = newValue;
          if (this.multiple) {
            newValue.map((option) => this.selectableCodes.push(option));
          } else {
            this.selectableCodes.push(newValue);
          }
        }
      },
    },
  },
  created() {
    this.debounceCodes = debounce(this.codeSearchChange, 300);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .multiselect__spinner {
  background: #dde4f5 !important;
}
::v-deep .invalid .multiselect__tags {
  border: 1px solid #dc3545;
  border-radius: 5px;
}
</style>