<template>
  <LineChart :chart-data="chartData" :chart-options="options" :styles="styles"/>
</template>

<script>
import 'chart.js/auto'
import { Line as LineChart } from 'vue-chartjs/legacy'


export default {
  name: 'ArLineChart',
  props: ['chartData', 'styles'],
  components: { LineChart },
  data() {
      return {
        options: {
            borderRadius: 10,
            tension: 0.3,

            maintainAspectRatio: false,
            responsive: true,
            
            plugins: {
              legend: {
                display: true
              }
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Month'
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'AR'
                    }
                }
            },
        }
      }
  },
}
</script>