<template>
  <div v-if="practice">
    <b-alert
      v-model="showAlert"
      variant="danger"
      style="margin-left: 20px"
      dismissible
    >
      <b>{{ alertMessage }}</b>
    </b-alert>
    <div class="d-flex justify-content-between" style="margin-left: 20px">
      <div>
        <b-input-group class="search-group">
          <b-input-group-prepend>
            <span class="input-group-text"
              ><b-icon icon="search"></b-icon
            ></span>
          </b-input-group-prepend>
          <b-form-input
            class="provider-search-input pl-1"
            type="search"
            v-model="searchText"
            placeholder="Search provider"
          />
          <b-input-group-append is-text>
            <span class="divider" />
            <span class="input-group-text">
              <b-form-checkbox
                v-model="doFilterNonBillingProviders"
                label="Show only billing providers"
              >
                Show only billing providers
              </b-form-checkbox>
            </span>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div style="margin-right: 20px">
        <b-button
          v-if="canEdit"
          v-b-modal.new-practice-provider
          class="avo-primary-btn"
          >Add Provider</b-button
        >
        <practice-provider-add
          :practice-id="practice_id"
          @submitted="getProviders()"
        />
      </div>
    </div>

    <b-table
      ref="providersTable"
      :filter-function="customFilterFunction"
      :filter="searchObject"
      :items="getProviders"
      @filtered="onFiltered"
      no-provider-paging
      no-provider-sorting
      no-provider-filtering
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      sort-icon-left
      empty-filtered-text="No providers match your search"
      empty-text="No providers found"
      show-empty
      class="mt-4"
    >
      <template #table-busy>
        <div class="d-flex justify-content-center">
          <b-spinner
            variant="primary"
            class="mr-2"
            style="width: 2rem; height: 2rem"
          />
          Busy working ...
        </div>
      </template>
      <template #cell(taxonomy_codes)="data">
        <provider-taxonomy-codes
          :provider="data.item"
          :taxonomy-codes="data.item.taxonomy_codes || []"
          @submitted="refreshTable()"
        />
      </template>

      <template #cell(provider_type)="data">
        {{ renderProviderType(data.item.provider_type) }}
      </template>

      <template #cell(edit)="data">
        <b-button
          v-if="canEdit"
          v-b-modal="'provider-edit-' + data.item.provider_id"
          class="avo-basic-btn"
          ><i class="fas fa-edit mr-2"></i>Edit</b-button
        >
        <provider-edit
          :selected-provider="data.item"
          @submitted="providerEditSubmittedCallback($event)"
        />
      </template>
    </b-table>
    <div>
      <b-pagination
        :total-rows="totalRows"
        :per-page="perPage"
        v-model="currentPage"
        align="center"
      />
    </div>
  </div>
</template>

<script>
import {
  make_url,
  authenticated_request,
  permissionsMixin,
  alertsMixin,
} from "../../common.js";
import PracticeProviderAdd from "./PracticeProviderAdd.vue";
import ProviderEdit from "../providers/ProviderEdit.vue";
import ProviderTaxonomyCodes from "../providers/ProviderTaxonomyCodes.vue";

export default {
  name: "PracticeProviders",
  mixins: [permissionsMixin, alertsMixin],
  components: {
    PracticeProviderAdd,
    ProviderEdit,
    ProviderTaxonomyCodes,
  },
  data() {
    return {
      basicFields: [
        {
          key: "full_name_with_suffix",
          label: "Provider",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "npi",
          label: "NPI",
        },
        {
          key: "ptan",
          label: "PTAN",
        },
        {
          key: "taxonomy_codes",
          label: "Taxonomy Codes",
        },
        {
          key: "start_date",
          label: "Start Date",
          sortable: true,
        },
      ],
      providers: [],
      searchText: "",
      doFilterNonBillingProviders: true,
      perPage: 10,
      totalRows: 1,
      currentPage: 1,

      loaded: false,
    };
  },
  methods: {
    getProviders() {
      const url = make_url(
        "/practices/" + this.practice_id + "/providers"
      ).toString();
      const self = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
        params: { per_page: 4000 }, //TODO: If there are too many providers per practice we'll need to revisit this
      })
        .then(function (response) {
          if (response) {
            const providers = response.data.practice_providers;
            self.providers = providers;
            self.totalRows = response.data.pagination.total;
            self.loaded = true;
            return providers || [];
          }
        })
        .catch(function (error) {
          self.showError(error);
          self.loaded = true;
          return self.providers || [];
        });

      return promise;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    refreshTable() {
      this.table.refresh();
    },
    providerEditSubmittedCallback(modal_id) {
      this.refreshTable();
      this.$bvModal.hide(modal_id);
    },
    //error methods
    showError(alertMessage) {
      this.showSuccess = false;
      this.setShowAlert(true, alertMessage);
    },
    hideError() {
      this.setShowAlert(false);
    },
    renderProviderType(providerTypeString) {
      if (providerTypeString === "Billing Provider") {
        return "Yes";
      } else if (providerTypeString === "Non-Billing Provider") {
        return "No";
      } else {
        return "";
      }
    },
  },
  computed: {
    searchObject: function () {
      if (this.searchText == "" && !this.doFilterNonBillingProviders) {
        return null;
      }
      return {
        text: this.searchText,
        doFilterNonBillingProviders: this.doFilterNonBillingProviders,
      };
    },
    customFilterFunction: function () {
      return (row, search) => {
        // Check that the providerType value is correct
        if (
          search.doFilterNonBillingProviders &&
          row.provider_type == "Non-Billing Provider"
        ) {
          return false;
        }
        // stringify the values in the row and check if search matches any
        // of the stringified values
        const searchLower = search.text.toLowerCase();
        return Object.values(row).some((value) => {
          return (
            JSON.stringify(value).toLowerCase().indexOf(searchLower) !== -1
          );
        });
      };
    },
    table: function () {
      return this.$refs.providersTable;
    },
    canEdit: function () {
      return this.hasPermission("edit");
    },
    fields: function () {
      let fields = this.basicFields;
      if (!this.doFilterNonBillingProviders) {
        fields = fields.concat([
          {
            key: "provider_type",
            label: "Billing",
            sortable: false,
            width: "100px",
          },
        ]);
      }
      if (this.canEdit) {
        fields = fields.concat([
          {
            key: "edit",
            label: "Edit",
            sortable: false,
            width: "100px",
          },
        ]);
      }
      return fields;
    },
    practice: function () {
      return this.$store.state.practices.selected_practice;
    },
    practice_id: function () {
      if (this.practice != null) {
        return this.practice.practice_id;
      }
      return null;
    },
    filterCount: function () {
      var count = 0;
      //TODO: count filters when they exist
      return count;
    },
    filterInputLabel: function () {
      const count = this.filterCount;
      if (count == 0) {
        return "Filters";
      } else {
        return count + " Filter(s)";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.provider-table {
  border: 1px solid #c9d2df;
  border-collapse: separate;
  border-spacing: 0px;
  border-radius: 5px;
}
::v-deep .provider-table th {
  padding: 20px;
  vertical-align: middle;
}
::v-deep .provider-table td {
  padding: 20px;
  vertical-align: middle;
}
::v-deep .provider-table tr:first-child th {
  border-bottom: 1px solid #c9d2df;
}
::v-deep .provider-table tr:nth-child(odd) td {
  background: #f7f8fa;
}
::v-deep .provider-table tr:last-child td:first-child {
  border-radius: 0px 0px 0px 5px;
}
::v-deep .provider-table tr:last-child td:last-child {
  border-radius: 0px 0px 5px 0px;
}

/* Styling the search bar group */
::v-deep .search-group {
  position: relative;
  margin-right: 20px;
  border: 1px solid #c9d2df;
  border-radius: 5px;
  max-width: 650px;
}
::v-deep .provider-search-input,
.input-group-text {
  height: 50px;
  border: none;
  border-radius: 5px;
  background: white;
  color: #9ca3ad;
}
.divider {
  display: inline-block;
  width: 0;
  height: 20px;
  margin: auto;
  border-right: 1.8px solid #d2d8e2;
}
::v-deep .provider-search-input::placeholder {
  color: #9ca3ad;
  font-size: 12px;
  opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 5px;
  color: #9ca3ad;
  background: white;
  font-size: 12px;
  height: 50px;
  min-width: 150px;
  text-align: left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active,
::v-deep .btn-secondary:not(:disabled):not(.disabled).active,
::v-deep .show > .btn-secondary.dropdown-toggle {
  color: #519eff !important;
  background-color: white;
}
::v-deep .b-dropdown .btn-secondary:focus {
  box-shadow: none !important;
}
.divider {
  display: inline-block;
  width: 0;
  height: 20px;
  margin: auto;
  border-right: 1.8px solid #d2d8e2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
  padding: 20px 30px 30px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
  width: 100.3%;
  right: -1px !important;
  left: auto !important;
  top: 45px !important;
  transform: none !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 16px 48px #1617182b !important;
}
::v-deep .flag-dropdown .dropdown-menu {
  width: 200px;
  padding: 10px;
  left: -1px !important;
  top: 48px !important;
  transform: none !important;
  font-size: 14px;
  font-weight: 500;
  color: #64728c;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 16px 48px #1617182b !important;
}
::v-deep .flag-dropdown .b-dropdown-form {
  padding: 0.2rem 0.8rem;
}
.selecter-label {
  color: #64728c;
  font-size: 14px;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 5px;
}
</style>