<template>
<div>
    <b-container fluid="xl">
    <div style="text-align:right">
        <b-icon font-scale="2.5" icon="x-circle" @click="returnToPracticeList" style="cursor:pointer"></b-icon>
    </div>
    <div class="avo-header-text" style="text-align:center;font-size:28px">
        New Practice Group
    </div>
    <div class="mt-4" style="text-align:center;margin-left:-5px">
        <hr class="circle-line">
        <div class="d-flex justify-content-center" style="margin-top:-34px; margin-right:-15px">
            <div class="d-flex-column mr-4">
                <div>
                    <b-iconstack style="margin-left:-7px">
                        <b-icon icon="circle-fill" font-scale="1.5" :style="step == 0 ? 'color:gray' : 'color:white'" />
                        <b-icon icon="circle" font-scale="1.5" />
                    </b-iconstack>
                </div>
                <div class="mt-2">
                    Practice Info
                </div>
            </div>
            <div class="d-flex-column ml-4 mr-4">
                <div>
                    <b-iconstack style="margin-left:-7px">
                        <b-icon icon="circle-fill" font-scale="1.5" :style="step == 1 ? 'color:gray' : 'color:white'" />
                        <b-icon icon="circle" font-scale="1.5" />
                    </b-iconstack>
                </div>
                <div class="mt-2">
                    Locations
                </div>
            </div>
            <div class="d-flex-column ml-4 mr-4">
                <div>
                    <b-iconstack style="margin-left:-7px">
                        <b-icon icon="circle-fill" font-scale="1.5" :style="step == 2 ? 'color:gray' : 'color:white'" />
                        <b-icon icon="circle" font-scale="1.5" />
                    </b-iconstack>
                </div>
                <div class="mt-2">
                    Providers
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="d-flex justify-content-center">
        <div>
            <b-alert
                v-model="showAlert"
                :variant="alertError?'danger':'success'"
                style="width:750px"
                dismissible>
                <b>{{alertMessage}}</b>
            </b-alert>
        </div>
    </div>
    <b-container style="width:500px" v-if="step==0">
        <b-card>
        <div class="avo-header-text mb-4">
            <b-icon icon="pencil" class="mr-2" />
            Practice Info
        </div>
        <hr style="margin-right:-20px;margin-left:-20px">
        <div>
            <b-form-group
            id="name"
            label="Name">
            <b-input-group>
                <b-form-input v-model="practice.name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="group_npi"
            label="Group NPI">
            <b-input-group>
                <b-form-input v-model="practice.group_npi"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="tax_id"
            label="Tax ID">
            <b-input-group>
                <b-form-input v-model="practice.tax_id"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        </b-card>
        <div class="mt-4" style="text-align:right">
                <b-button @click="submitPractice" class="avo-primary-btn">
                <b-overlay :show="submitLoading">
                    Continue
                </b-overlay>
                </b-button>
        </div>
    </b-container>
    
    <b-container style="width:500px" v-if="step==1">
    <b-card v-if="locations.length == 0" style="text-align:center">
        No locations created
    </b-card>
    <div v-for="(location, index) in locations" :key=index class="mb-2">
    <b-card v-if="locationIds[index] == null">
        <div class="d-flex justify-content-between">
            <div class="avo-header-text">
                <b-icon icon="pencil" class="mr-2" />
                New Location
            </div>
            <div>
                <b-icon @click="removeLocationForm(index)" icon="trash" font-scale="1.3" class="mt-2" style="cursor:pointer" />
            </div>
        </div>
        <hr style="margin-left:-20px;margin-right:-20px">
        <div>
            <b-form-group
            id="location_name"
            label="Name">
            <b-input-group>
                <b-form-input v-model="location.name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div class="d-flex">
            <div class="mr-2">
                <b-form-group
                id="fax"
                label="Fax">
                <b-input-group>
                    <b-form-input v-model="location.fax"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
            <div class="ml-2">
                <b-form-group
                id="telephone"
                label="Phone">
                <b-input-group>
                    <b-form-input v-model="location.telephone"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
        </div>
        <div>
            <b-form-group
            id="address_line1"
            label="Street address">
            <b-input-group>
                <b-form-input v-model="location.address_line1"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div class="d-flex">
            <div class="mr-2">
                <b-form-group
                id="city"
                label="City">
                <b-input-group>
                    <b-form-input v-model="location.city"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
            <div class="mr-2 ml-2">
                <b-form-group
                id="state"
                label="State">
                <b-input-group>
                    <b-form-input v-model="location.state"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
            <div class="ml-2">
                <b-form-group
                id="zip"
                label="Zip">
                <b-input-group>
                    <b-form-input v-model="location.zip"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
        </div>
        <div>
            <b-form-group
            id="ptan"
            label="PTAN">
            <b-input-group>
                <b-form-input v-model="location.ptan"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div style="text-align:right">
            <b-overlay :show="submitLoading">
            <b-button @click="submitLocation(index)" class="avo-primary-btn">Create location</b-button>
            </b-overlay>
        </div>
    </b-card>
    <b-card v-else>
        <div class="d-flex justify-content-between">
            <div>
                <b-icon icon="check2-circle" font-scale="1.3" />
                <span class="avo-header-text ml-2">
                    {{location.name}}
                </span>
            </div>
            <div>
                <b-icon @click="removeLocationForm(index)" icon="trash" font-scale="1.3" class="mt-2" style="cursor:pointer" />
            </div>
        </div>
    </b-card>
    </div>
    <div class="mt-2">
        <b-button @click="addNewLocationForm" class="avo-primary-btn" style="border:0px!important">
            <b-icon icon="plus-circle" />
            Add 
            <span v-if="locations.length > 0">
                another 
            </span>
            <span v-else>
                a
            </span>
            location
        </b-button>
    </div>
    <div class="mt-4" style="text-align:right">
            <b-button @click="previousStep()" class="avo-basic-btn mr-2">Back</b-button>
            <b-button @click="submitLocationsSection()" class="avo-primary-btn">Continue</b-button>
    </div>
    </b-container>
    
    <b-container style="width:500px" v-if="step==2">
    <b-card>
        <div class="avo-header-text">
            <b-icon icon="search" class="mr-2" />
            Providers
        </div>
        <hr style="margin-right:-20px;margin-left:-20px">
        <div>
            <b-form-group
            id="providers"
            label="Search providers">
            <b-input-group>
            <provider-multiselect
                v-model="providers"
                :multiple="true"
                />
            </b-input-group>
            </b-form-group>
        </div>
    </b-card>
    <div v-if="newProvider == null" class="mt-2">
        <b-button class="avo-primary-btn" style="border:0px!important" @click="newProvider = Object.assign({}, newProviderTemplate)">
        <b-icon icon="plus-circle" class="mr-2" />Add a new provider</b-button>
    </div>
    <b-card v-else class="mt-2">
        <div class="d-flex justify-content-between">
            <div class="avo-header-text">
                <b-icon icon="pencil" class="mr-2" />
                New Provider
            </div>
            <div>
                <b-icon @click="newProvider = null" icon="trash" font-scale="1.3" class="mt-2" style="cursor:pointer" />
            </div>
        </div>
        <hr style="margin-left:-20px;margin-right:-20px">
        <div>
            <b-form-group
            id="provider_given_name"
            label="First name">
            <b-input-group>
                <b-form-input v-model="newProvider.given_name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="provider_family_name"
            label="Last name">
            <b-input-group>
                <b-form-input v-model="newProvider.family_name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div class="d-flex">
            <div class="mr-4">
                <b-form-group
                id="provider_middle_name_or_initial"
                label="Middle name/initial">
                <b-input-group>
                    <b-form-input v-model="newProvider.middle_name_or_initial"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
            <div class="ml-4">
            <b-form-group
            id="provider_suffix"
            label="Suffix">
            <b-input-group>
                <b-form-input v-model="newProvider.suffix"></b-form-input>
            </b-input-group>
            </b-form-group>
            </div>
        </div>
        <div>
            <b-form-group
                id="provider_type"
                label="Billing type">
                <b-form-select v-model="newProvider.provider_type" :options="providerTypes">
                    <template #first>
                    <b-form-select-option :value="null" disabled>Select type</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="provider_npi"
            label="NPI">
            <b-input-group>
                <b-form-input v-model="newProvider.npi"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="provider_ptan"
            label="PTAN">
            <b-input-group>
                <b-form-input v-model="newProvider.ptan"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="telephone"
            label="Phone">
            <b-input-group>
                <b-form-input v-model="newProvider.telephone"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="email"
            label="Email">
            <b-input-group>
                <b-form-input v-model="newProvider.email"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div style="text-align:right">
            <b-overlay :show="submitLoading">
            <b-button @click="submitProvider()" class="avo-primary-btn">Create provider</b-button>
            </b-overlay>
        </div>
    
    </b-card>
    <div class="mt-4" style="text-align:right">
            <b-button @click="previousStep()" class="avo-basic-btn mr-2">Back</b-button>
            <b-button @click="associateProvidersWithPractice()" class="avo-primary-btn">Finish</b-button>
    </div>
    </b-container>
    
    </b-container>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
// import Multiselect from 'vue-multiselect'
import ProviderMultiselect from '../multiselects/ProviderMultiselect.vue'

export default {
    name: 'PracticeAdd',
    mixins: [alertsMixin],
    components: {
        ProviderMultiselect,
    },
    data(){
        return {
            step: 0,
            practice: {
                name: "",
                group_npi: null,
                tax_id: null,
            },
            practice_id: null,
            submittedPractice: null,
            locationTemplate: {
                name: "",
                fax: null,
                telephone: null,
                address_line1: null,
                city: null,
                state: null,
                zip: null,
                ptan: null
            },
            locations: [],
            locationIds: [],
            providers: [],
            providerTypes: [],
            newProvider: null,
            newProviderTemplate: {
                given_name: "",
                middle_name_or_initial: "",
                family_name: "",
                suffix: "",
                provider_type: -1,
                npi: null,
                ptan: null,
                telephone: null,
                email: null,
            },
            
            submitLoading: false,
            alertError: true,
        }
    },
    methods: {
        returnToPracticeList(){
            this.$store.commit("practices/setAddPractice", false);
        },
        nextStep(){
            if (this.step != 2){
                this.step = this.step + 1;
            }
            else{
                this.viewPractice();
            }
        },
        previousStep(){
            if (this.step != 0){
                this.step = this.step - 1;
            }
        },
        submitPractice(){
            this.submitLoading = true;
            var newPractice;
            if (this.submittedPractice == null){
                if ((this.practice.name == null) || (this.practice.name == "")){
                    this.showError("Please enter a practice name")
                    return
                }
                newPractice = {
                    name: this.practice.name
                }
                if ((this.practice.group_npi != null) && (this.practice.group_npi != "")){
                    newPractice.group_npi = this.practice.group_npi;
                }
                if ((this.practice.tax_id != null) && (this.practice.tax_id != "")){
                    newPractice.tax_id = this.practice.tax_id;
                }
                this.createPractice(newPractice);
            }
            else{
                if ((this.practice.name == null) || (this.practice.name == "")){
                    this.showError("Please enter a practice name")
                    return
                }
                newPractice = {}
                if (this.practice.name != this.submittedPractice.name){
                    newPractice.name = this.practice.name;
                }
                if (this.practice.group_npi != this.submittedPractice.group_npi){
                    newPractice.group_npi = this.practice.group_npi;
                }
                if (this.practice.tax_id != this.submittedPractice.tax_id){
                    newPractice.tax_id = this.practice.tax_id;
                }
                if (Object.keys(newPractice).length > 0){
                    this.modifyPractice(newPractice)
                }
                else{
                    this.submitLoading = false;
                    this.nextStep();
                }
            }
        },
        createPractice(newPractice){
            const url = make_url("/practices");
            const self = this;
            const promise = authenticated_request({
                method: "post",
                url: url,
                data: newPractice
            }).then(function(response){
                if (response){
                    self.practice_id = response.data;
                    self.submittedPractice = Object.assign({}, self.practice);
                    self.showSuccess("Practice created");
                    self.nextStep();
                }
                self.$emit('submitted');
            }).catch(function(error){
                self.showError(error);
            }).finally(()=>{
                self.submitLoading = false;
            });
            return promise;
        },
        modifyPractice(newPracticeData){
            const url = make_url("/practices/" + this.practice_id);
            const self = this;
            const promise = authenticated_request({
                method: "put",
                url: url,
                data: newPracticeData
            }).then(function(response){
                if (response){
                    self.showSuccess("Practice modified");
                    self.nextStep();
                }
                self.$emit('submitted');
            }).catch(function(error){
                self.showError(error);
            }).finally(()=>{
                self.submitLoading = false;
            });
            return promise;
        },
        addNewLocationForm(){
            this.locations.push(Object.assign({}, this.locationTemplate));
            this.locationIds.push(null);
        },
        removeLocationForm(index){
            if (this.locationIds[index] == null){
                this.locations.splice(index, 1);
                this.locationIds.splice(index, 1);
            }
            else{
                this.deleteLocation(index);
            }
        },
        submitLocation(index){
            this.submitLoading = true;
            if ((this.locations[index].name == null) || (this.locations[index].name == "")){
                this.showError("Please enter a practice location name")
                return
            }
            var newLocation = {
                "location": {},
                "address": {}
            }
            for (var key in this.locationTemplate){
                if ((this.locations[index][key] != null) && (this.locations[index][key] != "")){
                    if ((key != "name") && (key != "ptan")){
                        newLocation["address"][key] = this.locations[index][key];
                    }
                    else{
                        newLocation["location"][key] = this.locations[index][key];
                    }
                }
            }
            this.createLocation(newLocation, index);
        },
        createLocation(newLocation, index){
            const url = make_url("/forms/practices/" + this.practice_id + "/location_with_address");
            const self = this;
            const promise = authenticated_request({
                method: "post",
                url: url,
                data: newLocation
            }).then(function(response){
                if (response){
                    self.$set(self.locationIds, index, response.data);
                    self.showSuccess("Practice location created");
                }
            }).catch(function(error){
                self.showError(error);
            }).finally(()=>{
                self.submitLoading = false;
            });
            return promise;
        },
        deleteLocation(index){
            const url = make_url("/forms/practices/location_with_address/" + this.locationIds[index]);
            const self = this;
            const promise = authenticated_request({
                method: "delete",
                url: url,
            }).then(function(response){
                if (response){
                    self.locations.splice(index, 1);
                    self.locationIds.splice(index, 1);
                    self.showSuccess("Practice location deleted");
                }
            }).catch(function(error){
                self.showError(error);
            });
            return promise;
        },
        submitLocationsSection(){
            for (var i = 0; i < this.locationIds.length; i++){
                if (this.locationIds[i] == null){
                    this.showError("Please submit or delete all new location forms")
                    return
                }
            }
            this.nextStep();
        },
        getProviderFilters(){
            var self = this;
            authenticated_request({
                method: "get",
                url: make_url("/forms/providers/filters")
            }).then(function(response){
                self.providerTypes = response.data.types;
            }).catch(function(errors){
                self.showError(errors);
            });
        },
        submitProvider(){
            this.submitLoading = true;
            if ((this.newProvider.given_name == null) || (this.newProvider.given_name == "")
                || (this.newProvider.family_name == null) || (this.newProvider.family_name == "")){
                this.showError("Please enter a first and last name for the new provider")
                return
            }
            var newProviderData = {
                "provider": {},
                "address": {}
            }
            for (var key in this.newProviderTemplate){
                if ((this.newProvider[key] != null) && (this.newProvider[key] != "")){
                    if ((key != "email") && (key != "telephone")){
                        newProviderData["provider"][key] = this.newProvider[key];
                    }
                    else{
                        newProviderData["address"][key] = this.newProvider[key];
                    }
                }
            }
            this.createProvider(newProviderData);
        },
        createProvider(newProviderData){
            const url = make_url("/forms/provider_with_address");
            const self = this;
            const promise = authenticated_request({
                method: "post",
                url: url,
                data: newProviderData
            }).then(function(response){
                if (response){
                    var providerData = {
                        "full_name_with_suffix": self.newProvider.family_name + ", " + self.newProvider.given_name,
                        "provider_id": response.data
                    };
                    self.providers.push(providerData);
                    self.newProvider = null;
                    self.showSuccess("Provider created")
                }
            }).catch(function(error){
                self.showError(error);
            }).finally(()=>{
                self.submitLoading = false;
            });
            return promise;
        },
        associateProvidersWithPractice(){
            if (this.newProvider != null){
                this.showError("Please submit or delete the new provider form")
                return
            }
            if (this.providers.length > 0){
                const url = make_url("/practices/" + this.practice_id + "/providers/multiple");
                const self = this;
                const promise = authenticated_request({
                    method: "post",
                    url: url,
                    data: this.providers
                }).then(function(response){
                    if (response){
                        self.nextStep();
                    }
                }).catch(function(error){
                    self.showError(error);
                }).finally(()=>{
                    self.submitLoading = false;
                });
                return promise;
            }
            else{
                this.nextStep();
            }
        },
        viewPractice(){
            var selectedPractice = this.practice;
            selectedPractice['practice_id'] = this.practice_id;
            this.$store.commit("practices/selectPractice", selectedPractice);
            this.$store.commit("practices/setAddPractice", false);
        },
        
        //error methods
        showError(alertMessage){
            this.alertError = true;
            this.submitLoading = false;
            this.setShowAlert(true, alertMessage);
        },
        showSuccess(alertMessage){
            this.alertError = false;
            this.submitLoading = false;
            this.setShowAlert(true, alertMessage);
        },
        hideError(){
            this.setShowAlert(false);
        },
    },
    created(){
        this.getProviderFilters();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
hr.circle-line{
  min-width: 250px;
  border:black 1px solid;
  margin-left:430px;
  margin-right:430px;
}
</style>