<template>
<div id="add-practice-provider">
<b-modal id="new-practice-provider" @hide="resetModal" :title="!new_provider ? 'Add provider to practice' : 'Create new provider'" hide-footer size="lg">
<b-nav tabs class="mb-4 nav-section">
    <b-nav-item :active="!new_provider" @click="setNewProvider(false)">Existing provider</b-nav-item>
    <b-nav-item :active="new_provider" @click="setNewProvider(true)">New provider</b-nav-item>
</b-nav>
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div v-if="new_provider">
        <provider-add :practice-id="practiceId" 
            @associatedProviderWithPractice="hideNewProviderModal($event)" />
    </div>
    <div v-else class="mb-4">
        <b-form-group
        id="provider"
        label="Provider:">
            <b-input-group>
                <provider-multiselect v-model="provider" :multiple="false" :close-on-select="true"/>
            </b-input-group>
        </b-form-group>
    </div>
    <b-row v-if="!new_provider">
        <b-col style="text-align:center">
            <b-button @click="onSubmit" class="avo-primary-btn">
                    Add provider to practice
            </b-button>
        </b-col>
    </b-row>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import ProviderMultiselect from '../multiselects/ProviderMultiselect.vue'
import ProviderAdd from '../providers/ProviderAdd.vue'

export default {
  /**
   * Modal wrapper around the ProviderAdd component
   * @displayName Practice Provider Add
   */
  name: 'PracticeProviderAdd',
  components:{
    ProviderMultiselect,
    ProviderAdd,
  },
  mixins: [alertsMixin],
  props: ['practiceId'],
  data: function(){
      return {
            provider: {},
            new_provider: false,
      }
  },
  methods: {
    clearData(){
        this.provider = {}
        this.hideError();
    },
    validateInputs(){
        this.hideError();
        
        if (!this.new_provider){
            if (this.provider){
                return true;
            }
            else{
                this.showError("Please select a provider");
                return false;
            }
        }
        
        return true
    },
    addProvider(){
        const url = make_url("/providers");
        const self = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: this.provider
        }).then(function(response){
            if (response){
                self.associateProviderWithPractice(response.data);
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    associateProviderWithPractice(provider_id){
        const url = make_url("/practices/" + this.practiceId + "/providers");
        const self = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: {
                provider_id: provider_id
            }
        }).then(function(response){
            if (response){
                self.$bvModal.hide('new-practice-provider');
                self.$emit('submitted');
            }
        }).catch(function(error){
            if (error.response.status == 400){
                self.showError("Provider is already associated with practice")
            }
            else{
                self.showError(error);
            }
        });
        return promise;
    },
    hideNewProviderModal(){
        this.$bvModal.hide('new-practice-provider');
        this.$emit('submitted');
    },
    onSubmit(){
        if (this.validateInputs()){
            if (this.new_provider){
                this.addProvider();
            }
            else{
                this.associateProviderWithPractice(this.provider.provider_id);
            }
        }
    },
    setNewProvider(bool){
        if (this.new_provider != bool){
            this.clearData();
        }
        this.new_provider = bool;
    },
    resetModal(){
        this.hideError();
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
    created(){
        var self = this;
        authenticated_request({
            method: "get",
            url: make_url("/forms/providers/filters")
        }).then(function(response){
            self.types = response.data.types;
        }).catch(function(errors){
            self.showError(errors);
        });
    }
}
</script>

<style scoped>
</style>