<template>
<div v-if="practice">
    <report-list :id='practice.practice_id' idType="practice" />
</div>
</template>

<script>
import ReportList from '../reports/ReportList.vue'

export default {
    name: 'PracticeReports',
    components: {
        ReportList
    },
    data(){
        return{
        };
    },
    methods: {
    },
    computed: {
        practice: function(){
            return this.$store.state.practices.selected_practice;
        },
    },
    watch: {
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>