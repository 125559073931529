<template>
<div id="practice-location-edit">
<b-modal :id="modal_id" @hide="resetModal" title="Edit Practice Location" hide-footer size="xl">
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <b-row>
        <b-col>
            <b-form-group
            id="name"
            label="Name:">
            <b-input-group>
                <b-form-input v-model="form.name" :placeholder="selectedLocation.name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="ptan"
            label="PTAN:">
            <b-input-group>
                <b-form-input v-model="form.ptan" :placeholder="selectedLocation.ptan"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="telephone"
            label="Phone:">
            <b-input-group>
                <b-form-input v-model="form.telephone" :placeholder="selectedLocation.telephone"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="fax"
            label="Fax:">
            <b-input-group>
                <b-form-input v-model="form.fax" :placeholder="selectedLocation.fax"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="address_1"
            label="Address Line 1:">
            <b-input-group>
                <b-form-input v-model="form.address_line1" :placeholder="selectedLocation.address_line1"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="address_2"
            label="Address Line 2:">
            <b-input-group>
                <b-form-input v-model="form.address_line2" :placeholder="selectedLocation.address_2"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="city"
            label="City:">
            <b-input-group>
                <b-form-input v-model="form.city" :placeholder="selectedLocation.city"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="state"
            label="State:">
            <b-input-group>
                <b-form-input v-model="form.state" :placeholder="selectedLocation.state"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="country"
            label="Country:">
            <b-input-group>
                <b-form-input v-model="form.country" :placeholder="selectedLocation.country"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="zip"
            label="Zip:">
            <b-input-group>
                <b-form-input v-model="form.zip" :placeholder="selectedLocation.zip"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col style="text-align:left">
            <b-button @click="deletePracticeLocation" class="avo-danger-btn">
                <i class="fas fa-trash-alt mr-2"></i> Delete Practice Location
            </b-button>
        </b-col>
        <b-col style="text-align:right">
            <b-button @click="onSubmit" class="avo-primary-btn">
                Submit Changes
            </b-button>
        </b-col>
    </b-row>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'

export default {
  name: 'PracticeLocationEdit',
  mixins: [ alertsMixin ],
  props: ['selectedLocation',],
  data: function(){
      return {
            form: {
                name: null,
                ptan: null,
                telephone: null,
                fax: null,
                address_line1: null,
                address_line2: null,
                city: null,
                state: null,
                country: null,
                zip: null,
            },
            location: {},
      }
  },
  methods: {
    clearData(){
        this.form = {
            name: null,
            ptan: null,
            telephone: null,
            fax: null,
            address_line1: null,
            address_line2: null,
            city: null,
            state: null,
            country: null,
            zip: null,
        }
        this.location = {}
        this.hideError();
    },
    validateInputs(){
        this.hideError();
        
        //validate inputs
        if ((this.form.name) == null || (this.form.name == "")){
            this.form.name = this.selectedLocation.name;
        }
        
        this.location = {
            location: {
                name: this.form.name
            }
        }
        
        if ((this.form.ptan != null) && (this.form.ptan != "")){
            this.location.location.ptan = this.form.ptan;
        }
        
        var address_fields = [this.form.telephone, this.form.fax, this.form.address_line1, this.form.address_line2,
            this.form.city, this.form.state, this.form.country, this.form.zip]
        var field_names = ['telephone', 'fax', 'address_line1', 'address_line2', 'city', 'state', 'country', 'zip']
        
        for (var i = 0; i < address_fields.length; i++){
            if ((address_fields[i] != null) && (address_fields[i] != "")){
                if (this.location.address){
                    if (address_fields[i]) {
                        this.location.address[field_names[i]] = address_fields[i];
                    }
                }
                else{
                    this.location.address = {}
                    if (address_fields[i]) {
                        this.location.address[field_names[i]] = address_fields[i];
                    }
                }
            }
        }
        
        return true
    },
    editPracticeLocationAddress(){
        const address_url = make_url("/practices/" + this.practice_id + "/locations/" + this.location_id + "/address");
        const self = this;
        const address_promise = authenticated_request({
            method: "put",
            url: address_url,
            data: this.location.address
        }).catch(function(error){
            self.showError(error);
        });
        return address_promise;
    },
    editPracticeLocation(){
        const url = make_url("/practices/" + this.practice_id + "/locations/" + this.location_id);
        const self = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
            data: this.location.location
        }).then(function(response){
            if (response){
                self.$bvModal.hide(self.modal_id);
                self.$emit('submitted');
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    // TODO: Can't delete practice locations while claims tied to them exist
    deletePracticeLocation(){
        const url = make_url("/practices/" + this.practice_id + "/locations/" + this.location_id);
        const self = this;
        const promise = authenticated_request({
            method: "delete",
            url: url,
        }).then(function(response){
            if (response){
                self.$bvModal.hide(self.modal_id);
                self.$emit('submitted');
            }
        }).catch(function(error){
            self.showError(error.response?.data?.msg || error);
        });
        return promise;
    },
    onSubmit(){
        if (this.validateInputs()){
            if (this.location.address){
                this.editPracticeLocationAddress();
                this.editPracticeLocation();
            }
            else{
                this.editPracticeLocation();
            }
        }
    },
    resetModal(){
        this.hideError();
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
    computed: {
        practice_id: function(){
            return this.$store.state.practices.selected_practice.practice_id
        },
        location_id: function(){
            return this.selectedLocation.location_id
        },
        modal_id: function(){
            return 'practice-location-edit-' + this.location_id
        },
    }
}
</script>

<style scoped>
</style>