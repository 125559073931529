<template>
  <div v-if="practice">
    <b-row>
      <b-col>
        <b-alert
            v-model="showAlert"
            variant="danger"
            dismissible>
            <b>{{alertMessage}}</b>
        </b-alert>
      </b-col>
    </b-row>
    <back-button v-if="practice" @click="clearPractice()"
      >Back to Practices</back-button
    >
    <b-row class="mt-4 ml-2 mr-4">
      <b-col cols="6" class="d-flex flex-column">
        <div class="avo-text-light">Practice Group</div>
        <div class="avo-header-text" style="font-size: 36px">
          {{ practice.name }}
        </div>
      </b-col>
      <b-col cols="4" class="d-flex justify-content-center">
        <div
          class="mt-2 mr-2"
          style="
            padding-left: 10px;
            border-left: 4px solid var(--blue);
            margin-bottom: 13px;
          "
        ></div>
        <div class="d-flex flex-column mr-4">
          <div>
            <div class="avo-header-text bold" style="font-size: 28px">
              {{ practice.count_locations || 0 }}
            </div>
            <div>Locations</div>
          </div>
        </div>
        <div
          class="mt-2 ml-4 mr-2"
          style="
            padding-left: 10px;
            border-left: 4px solid #fd4739;
            margin-bottom: 13px;
          "
        ></div>
        <div class="d-flex flex-column">
          <div class="avo-header-text bold" style="font-size: 28px">
            {{ practice.count_providers || 0 }}
          </div>
          <div>Providers</div>
        </div>
      </b-col>
      <b-col v-if="hasPermission('edit')" class="d-flex justify-content-end">
        <div>
          <b-button
            class="avo-primary-btn"
            v-b-modal.practice-edit
            :disabled="editPracticeLoading"
            ><b-overlay :show="editPracticeLoading" variant="white"
              >Edit Practice</b-overlay
            ></b-button
          >
          <practice-edit @loaded="editPracticeLoading = false" @updated="onPracticeUpdated" />
        </div>
      </b-col>
    </b-row>
    <div class="mt-4">
      <b-nav tabs class="nav-section">
        <b-nav-item
          v-if="hasPermission('summary_tab')"
          :active="activeTab == 'PracticeSummary'"
          @click="setActiveTab('PracticeSummary')"
          >Summary</b-nav-item
        >
        <b-nav-item
          :active="activeTab == 'PracticeDetails'"
          @click="setActiveTab('PracticeDetails')"
          >Details</b-nav-item
        >
        <b-nav-item
          :active="activeTab == 'PracticeLocations'"
          @click="setActiveTab('PracticeLocations')"
          >Locations</b-nav-item
        >
        <b-nav-item
          :active="activeTab == 'PracticeProviders'"
          @click="setActiveTab('PracticeProviders')"
          >Providers</b-nav-item
        >
        <b-nav-item
          :active="activeTab == 'PracticeFees'"
          @click="setActiveTab('PracticeFees')"
          >Fee Schedule</b-nav-item
        >
        <b-nav-item
          :active="activeTab == 'PracticeResourceLogins'"
          @click="setActiveTab('PracticeResourceLogins')"
          >Logins</b-nav-item
        >
        <b-nav-item
          v-if="hasPermission('reports_tab')"
          :active="activeTab == 'PracticeReports'"
          @click="setActiveTab('PracticeReports')"
          >Reports</b-nav-item
        >
      </b-nav>
      <keep-alive>
        <component
          class="mt-4"
          :is="activeTab"
          @practiceUpdated="refreshSelectedPracticeData"
        ></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import BackButton from "../BackButton.vue";
import PracticeSummary from "./PracticeSummary.vue";
import PracticeDetails from "./PracticeDetails.vue";
import PracticeLocations from "./PracticeLocations.vue";
import PracticeProviders from "./PracticeProviders.vue";
import PracticeFees from "./PracticeFees.vue";
import PracticeResourceLogins from "./PracticeResourceLogins.vue";
import PracticeEdit from "./PracticeEdit.vue";
import PracticeReports from "./PracticeReports.vue";
import {
  permissionsMixin,
  alertsMixin,
  authenticated_request,
  make_url,
} from "../../common.js";

export default {
  name: "PracticeView",
  mixins: [permissionsMixin, alertsMixin],
  components: {
    BackButton,
    PracticeSummary,
    PracticeDetails,
    PracticeLocations,
    PracticeProviders,
    PracticeFees,
    PracticeResourceLogins,
    PracticeEdit,
    PracticeReports,
  },
  data() {
    return {
      activeTab: this.hasPermission("summary_tab")
        ? "PracticeSummary"
        : "PracticeDetails",
      editPracticeLoading: true,
    };
  },
  methods: {
    onPracticeUpdated() {
      this.refreshSelectedPracticeData();
      this.$emit("updated");
    },
    refreshSelectedPracticeData() {
      var self = this;
      var url = make_url("/reports/practices");
      authenticated_request({
        method: "get",
        url: url,
        params: {
          practice_id: this.practice.practice_id,
        },
      })
        .then(function (response) {
          var practice = response.data.practices[0];
          self.$store.commit("practices/selectPractice", practice);
          const address_id = practice.address;
          const billing_address_id = practice.billing_address;
          if (address_id) {
            self.getAddress(address_id, false);
          } else {
            self.$store.commit("practices/setSelectedPracticeAddress", null);
          }
          if (billing_address_id) {
            self.getAddress(billing_address_id, true);
          } else {
            self.$store.commit(
              "practices/setSelectedPracticeBillingAddress",
              null
            );
          }
        })
        .catch(function (errors) {
          self.showError(errors);
        });
    },
    getAddress: function(address_id, billing){
        const url = make_url("/addresses/" + address_id).toString();
        const self = this;
        authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            if (response) {
                if (billing){
                    self.$store.commit("practices/setSelectedPracticeBillingAddress", response.data);
                }
                else{
                    self.$store.commit("practices/setSelectedPracticeAddress", response.data);
                }
            }
        }).catch(function(error){
            self.showError(error);
        });
    },
    clearPractice() {
      this.$store.commit("practices/clearPractice");
    },
    // For tab navigation
    setActiveTab: function (component) {
      this.activeTab = component;
    },
    showError(error) {
      this.setShowAlert(true, error);
    },
    hideError() {
      this.setShowAlert(false);
    },
  },
  computed: {
    practice: function () {
      return this.$store.state.practices.selected_practice;
    },
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-section {
  border-bottom: 1px solid #e6eef9;
  margin-left: -15px;
  margin-right: -15px;
  padding-right: 35px;
  padding-left: 35px;
}
</style>