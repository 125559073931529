<template>
  <div>
    <b-badge
      pill
      href="#!"
      v-for="(code, index) in taxonomyCodes"
      :key="index"
      variant="light"
      class="mr-2 p-2 mb-2 border border-1"
      @click="removePracticeTaxonomyCode(code)"
      v-b-tooltip.hover="{ variant: 'dark' }"
      :title="`Code: ${code.code}`"
    >
      {{ renderTaxonomyCode(code) }}
      <b-icon icon="x-circle-fill" class="ml-1" v-if="canEdit" />
    </b-badge>
    <b-badge
      pill
      href="#!"
      variant="primary"
      class="mr-2 border border-primary border-1 p-2 mt-1"
      v-if="canEdit"
      @click="showModal()"
    >
      <b-icon icon="plus-circle-fill" class="mr-2" />
      Add Taxonomy Code
    </b-badge>

    <b-modal
      :id="modalId"
      title="Add Practice Taxonomy Code"
      hide-footer
      size="m"
    >
      <b-form @submit="onSubmit()">
        <b-form-group id="taxonomy_code" label="New Taxonomy Code">
          <b-input-group>
            <taxonomy-code-multiselect v-model="code" />
          </b-input-group>
        </b-form-group>

        <div class="d-flex justify-content-end mt-4">
          <b-button class="avo-basic-btn" @click="hideModal()">
            Cancel
          </b-button>
          <b-button class="avo-primary-btn ml-2" @click="onSubmit()">
            Add
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  make_url,
  permissionsMixin,
  authenticated_request,
} from "../../common.js";
import TaxonomyCodeMultiselect from "../multiselects/TaxonomyCodeMultiselect.vue";

export default {
  name: "PracticeTaxonomyCodes",
  components: {
    TaxonomyCodeMultiselect,
  },
  props: {
    practice: {
      type: Object,
      required: true,
    },
    taxonomyCodes: {
      type: Array,
      required: true,
    },
  },
  mixins: [permissionsMixin],
  data() {
    return {
      code: null,
    };
  },
  methods: {
    showModal() {
      if (!this.canEdit) {
        return;
      }
      this.$bvModal.show(this.modalId);
    },
    hideModal() {
      this.$bvModal.hide(this.modalId);
    },
    removePracticeTaxonomyCode(code) {
      if (!this.canEdit) {
        return;
      }
      if (
        confirm(
          `Remove taxonomy code ${code.code} from practice ${this.practice.name}?`
        ) !== true
      ) {
        return;
      }
      const url = make_url(
        `/practices/${this.practice.practice_id}/taxonomy_codes`
      ).toString();

      const vm = this;
      authenticated_request({
        method: "delete",
        url: url,
        params: { code: code.code },
      }).then(
        (response) => {
          vm.$emit("submitted");
        },
        (error) => {
          console.log(error);
        }
      );
    },
    addPracticeTaxonomyCode() {
      const url = make_url(
        `/practices/${this.practice.practice_id}/taxonomy_codes`
      ).toString();

      const vm = this;
      authenticated_request({
        url: url,
        method: "post",
        data: { code: this.code.code },
      }).then(
        (response) => {
          vm.$emit("submitted");
          vm.hideModal();
        },
        (error) => {
          console.log(error);
          vm.hideModal();
        }
      );
    },
    onSubmit() {
      if (!this.canEdit) {
        return;
      }
      this.addPracticeTaxonomyCode();
    },
    renderTaxonomyCode(code) {
      if (code.name) {
        return code.name;
      }
      return code.code || "-";
    },
  },
  computed: {
    modalId: function () {
      return "add-taxonomy-code-modal-" + this._uid;
    },
    canEdit: function () {
      return this.hasPermission("edit-practice-taxonomy-codes");
    },
  },
};
</script>
