<template>
    <div>
        <transition name="slide-fade">
            <keep-alive include="PracticesList">
                <practice-view @updated="onPracticesUpdated" v-if="practiceSelected" />
                <practice-add @submitted="onPracticesUpdated" v-else-if="addPractice" />
                <practices-list v-else />
            </keep-alive>
        </transition>
    </div>
</template>

<script>
import PracticesList from "../components/practices/PracticesList.vue"
import PracticeView from "../components/practices/PracticeView.vue"
import PracticeAdd from "../components/practices/PracticeAdd.vue"

export default {
  name: 'Practices',
  components: {
      PracticesList,
      PracticeView,
      PracticeAdd
  },
  data(){
      return {
          practices: [],
          search: {}
      }
  },
  methods: {
      onPracticesUpdated(){
        this.$store.commit("practices/setPracticeListNeedsRefresh", true);
      },
  },
  computed: {
      practiceSelected: function(){
          return (this.$store.state.practices.selected_practice != null);
      },
      addPractice: function(){
          return this.$store.state.practices.add_practice;
      }
  },
  deactivated() {
      this.$store.commit("practices/clearPractice");
  }
}
</script>