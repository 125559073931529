<template>
<div id="edit-practice">
<b-modal id="practice-edit" @hide="resetForm" title="Edit Practice" hide-footer size="md">
<b-form>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
        <div>
            <b-form-group
            id="name"
            label="Name">
            <b-input-group>
                <b-form-input v-model="practice.name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="type"
            label="Type">
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    v-model="practice.practice_type" 
                    :options="practice_types"
                    placeholder="Select a practice type" 
                    label="name" 
                    :searchable="true"
                    :show-labels="false"
                    :allow-empty="true"
                    :internalSearch="true"
                    track-by="value">
                </multiselect>
            </b-input-group>
            </b-form-group>
        </div>
        <div class="d-flex">
            <div class="flex-fill mr-2">
                <b-form-group
                id="tax_id"
                label="Tax ID">
                <b-input-group>
                    <b-form-input v-model="practice.tax_id"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
            <div class="flex-fill ml-2">
                <b-form-group
                id="group_npi"
                label="Group NPI">
                <b-input-group>
                    <b-form-input v-model="practice.group_npi"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
        </div>
        <b-card class="mt-4 mb-4">
            <div class="avo-header-text">
                Primary Physical Address
            </div>
            <hr style="margin-right:-20px;margin-left:-20px">
            <div>
                <b-form-group
                id="address_line1"
                label="Street address">
                <b-input-group>
                    <b-form-input v-model="address.address_line1"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
            <div class="d-flex">
                <div class="mr-2">
                    <b-form-group
                    id="city"
                    label="City">
                    <b-input-group>
                        <b-form-input v-model="address.city"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
                <div class="mr-2 ml-2">
                    <b-form-group
                    id="state"
                    label="State">
                    <b-input-group>
                        <b-form-input v-model="address.state"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
                <div class="ml-2">
                    <b-form-group
                    id="zip"
                    label="Zip">
                    <b-input-group>
                        <b-form-input v-model="address.zip"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
            </div>
            <div class="d-flex">
                <div class="mr-2">
                    <b-form-group
                    id="fax"
                    label="Fax">
                    <b-input-group>
                        <b-form-input v-model="address.fax"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
                <div class="ml-2">
                    <b-form-group
                    id="telephone"
                    label="Phone">
                    <b-input-group>
                        <b-form-input v-model="address.telephone"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
            </div>
        </b-card>
        <b-card>
            <div class="avo-header-text">
                Billing Address
            </div>
            <hr style="margin-right:-20px;margin-left:-20px">
            <div>
                <b-form-group
                id="address_line1"
                label="Street address">
                <b-input-group>
                    <b-form-input v-model="billing_address.address_line1"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
            <div class="d-flex">
                <div class="mr-2">
                    <b-form-group
                    id="city"
                    label="City">
                    <b-input-group>
                        <b-form-input v-model="billing_address.city"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
                <div class="mr-2 ml-2">
                    <b-form-group
                    id="state"
                    label="State">
                    <b-input-group>
                        <b-form-input v-model="billing_address.state"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
                <div class="ml-2">
                    <b-form-group
                    id="zip"
                    label="Zip">
                    <b-input-group>
                        <b-form-input v-model="billing_address.zip"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
            </div>
        </b-card>
        <div class="mt-4" style="text-align:right">
            <b-button @click="submitPractice" variant="outline-primary" class="avo-primary-btn" :disabled="submitting">
                <b-overlay :show="submitting" variant="white">Submit Practice Changes</b-overlay>
            </b-button>
        </div>
    </div>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'PracticeEdit',
  components: {
      Multiselect
  },
  mixins: [alertsMixin],
  data: function(){
      return {
          practice: {
              'name': null,
              'practice_type': -1,
              'tax_id': null,
              'group_npi': null

          },
          address: {
              'address_line1': null,
              'city': null,
              'state': null,
              'zip': null,
              'fax': null,
              'telephone': null
          },
          billing_address: {
              'address_line1': null,
              'city': null,
              'state': null,
              'zip': null,
          },
          practice_types: [],

          submitting: false,
      }
  },
  methods: {
    getAddress: function(address_id, billing){
        const url = make_url("/addresses/" + address_id).toString();
        const self = this;
        authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            if (response) {
                self.hideError();
                if (billing){
                    self.$store.commit("practices/setSelectedPracticeBillingAddress", response.data);
                }
                else{
                    self.$store.commit("practices/setSelectedPracticeAddress", response.data);
                }
            }
        }).catch(function(error){
            self.showError(error);
        });
    },
    submitPractice(){
        this.submitting = true;
        
        var data = {
            'practice': null,
            'address': null,
            'billing_address': null,
        }
        var data_practice = {};
        for (var key in this.practice){
            if (this.practice[key] != this.selectedPractice[key]){
                if (key != 'practice_type'){
                    data_practice[key] = this.practice[key];
                }
                else{
                    data_practice[key] = this.practice[key].value;
                }
            }
        }
        if (Object.keys(data_practice).length > 0){
            data['practice'] = data_practice;
        }
        var data_address = {};
        for (key in this.address){
            if ((!this.selectedPracticeAddress)||(this.address[key] != this.selectedPracticeAddress[key])){
                data_address[key] = this.address[key];
            }
        }
        if (Object.keys(data_address).length > 0){

            data['address'] = data_address;
        }
        var data_billing_address = {};
        for (key in this.billing_address){
            if ((!this.selectedPracticeBillingAddress)||(this.billing_address[key] != this.selectedPracticeBillingAddress[key])){
                data_billing_address[key] = this.billing_address[key];
            }
        }
        if (Object.keys(data_billing_address).length > 0){
            data['billing_address'] = data_billing_address;
        }

        if ((data['practice'] == null) && (data['address'] == null) && (data['billing_address'] == null)){
            this.$bvModal.hide('practice-edit');
            this.submitting = false
            return
        }

        var self = this;
        authenticated_request({
            method: "put",
            url: make_url("/forms/practice_with_address/" + this.practice.practice_id),
            data: data
        }).then(function(response){
            if (response){
                self.refreshSelectedPracticeData();
            }
            self.$emit("updated");
        }).catch(function(errors){
            self.showError(errors);
            self.submitting = false
        });
    },
    refreshSelectedPracticeData(){
        var self = this;
        var url = make_url("/reports/practices");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                'practice_id': this.selectedPractice.practice_id
            }
        }).then(function(response){
            var practice = response.data.practices[0];
            self.$store.commit("practices/selectPractice", practice);
            const address_id = practice.address;
            const billing_address_id = practice.billing_address;
            if (address_id){
                self.getAddress(address_id, false);
            }
            else{
                self.$store.commit("practices/setSelectedPracticeAddress", null);
            }
            if (billing_address_id){
                self.getAddress(billing_address_id, true);
            }
            else{
                self.$store.commit("practices/setSelectedPracticeBillingAddress", null);
            }
            self.$bvModal.hide('practice-edit');
        }).catch(function(errors){
            self.showError(errors);
        }).finally(()=>{
            self.submitting = false;
        });
    },
    resetForm(){
        var key;
        this.practice = Object.assign({}, this.selectedPractice);
        if (this.selectedPractice.practice_type != null){
            this.practice.practice_type = this.practice_types.find(practiceTypes => practiceTypes.value == this.selectedPractice.practice_type.toString())
        }
        if (this.selectedPracticeAddress){
            for (key in this.address){
                this.address[key] = this.selectedPracticeAddress[key];
            }
        }
        else{
            this.address = {
              'address_line1': null,
              'city': null,
              'state': null,
              'zip': null,
              'fax': null,
              'telephone': null
            }
        }
        if (this.selectedPracticeBillingAddress){
            for (key in this.billing_address){
                this.billing_address[key] = this.selectedPracticeBillingAddress[key];
            }
        }
        else{
            this.billing_address = {
              'address_line1': null,
              'city': null,
              'state': null,
              'zip': null,
            }
        }
        this.$emit('loaded');
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
  computed: {
    selectedPractice() {
        return this.$store.state.practices.selected_practice;
    },
    selectedPracticeAddress() {
        return this.$store.state.practices.selected_practice_address;
    },
    selectedPracticeBillingAddress() {
        return this.$store.state.practices.selected_practice_billing_address;
    },
    practiceTypes(){
        return this.$store.state.practices.practice_types;
    }
  },
  watch: {
      selectedPractice: function() {
        this.practice = Object.assign({}, this.selectedPractice);
        if (this.selectedPractice.practice_type != null){
            this.practice.practice_type = this.practice_types.find(practiceTypes => practiceTypes.value == this.selectedPractice.practice_type.toString())
        }
      },
      selectedPracticeAddress: function(){
        if (this.selectedPracticeAddress){
            for (var key in this.address){
                this.address[key] = this.selectedPracticeAddress[key];
            }
        }
        else{
            this.address = {
              'address_line1': null,
              'city': null,
              'state': null,
              'zip': null,
              'fax': null,
              'telephone': null
            }
        }
      },
      selectedPracticeBillingAddress: function(){
        if (this.selectedPracticeBillingAddress){
            for (var key in this.billing_address){
                this.billing_address[key] = this.selectedPracticeBillingAddress[key];
            }
        }
        else{
            this.billing_address = {
              'address_line1': null,
              'city': null,
              'state': null,
              'zip': null,
            }
        }
      },
      practiceTypes: function(){
          this.practice_types = [];
          for (var key in this.practiceTypes){
              this.practice_types.push({
                  'value': key,
                  'name': this.practiceTypes[key]
              })
          }
      }
  },
  async mounted(){
    for (var key in this.practiceTypes){
        this.practice_types.push({
            'value': key,
            'name': this.practiceTypes[key]
        })
    }
    this.resetForm();
  }
}
</script>

<style scoped>

</style>