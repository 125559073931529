<template>
<b-container v-if="practice" class="p-0">
    <!-- {{practice}} -->
    <b-row>
        <b-col>
            <b-overlay :show="claimStatsLoading">
            <b-card class="avo-info-card mb-4">
                <div class="d-flex flex-column justify-content-center align-items-start">
                    <div class="avo-text-light">Total Billed Value</div>
                    <div class="total-amount-text">${{parseFloat(claimStats.gross_charges).toLocaleString()}}</div>
                    <div class="d-flex align-items-center" v-if="grossChargeDirection != 'no-change'">
                        <div :class="grossChargeDirection">
                            <b-icon
                                :icon="grossChargeDirection == 'increase' ? 'caret-up-fill' : 'caret-down-fill'"></b-icon>
                            {{grossPercentChange}}%
                        </div>
                        <div class="avo-text-light-small ml-2">Prior month</div>
                    </div>
                    <div v-else class="avo-text-light-small">Same as prior month</div>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-start">
                    <div class="avo-text-light">Total Patient Count</div>
                    <div class="total-amount-text">{{claimStats.patient_visits}}</div>
                    <div class="d-flex align-items-center" v-if="patientVisitDirection != 'no-change'">
                        <div :class="patientVisitDirection">
                            <b-icon
                                :icon="patientVisitDirection == 'increase' ? 'caret-up-fill' : 'caret-down-fill'"></b-icon>
                            {{visitPercentChange}}%
                        </div>
                        <div class="avo-text-light-small ml-2">Prior month</div>
                    </div>
                    <div v-else class="avo-text-light-small">Same as prior month</div>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-start">
                    <div class="avo-text-light">Total Paid</div>
                    <div class="total-amount-text">${{parseFloat(claimStats.collection).toLocaleString()}}</div>
                    <div class="d-flex align-items-center" v-if="collectionDirection != 'no-change'">
                        <div :class="collectionDirection">
                            <b-icon
                                :icon="collectionDirection == 'increase' ? 'caret-up-fill' : 'caret-down-fill'"></b-icon>
                            {{collectionPercentChange}}%
                        </div>
                        <div class="avo-text-light-small ml-2">Prior month</div>
                    </div>
                    <div v-else class="avo-text-light-small">Same as prior month</div>
                </div>
                <!--div class="d-flex flex-column justify-content-center align-items-start">
                    <div class="avo-text-light">Adjustments</div>
                    <div class="total-amount-text">${{parseFloat(claimStats.adjustments).toLocaleString()}}</div>
                    <div class="d-flex align-items-center" v-if="adjustmentDirection != 'no-change'">
                        <div :class="adjustmentDirection">
                            <b-icon
                                :icon="adjustmentDirection == 'increase' ? 'caret-up-fill' : 'caret-down-fill'"></b-icon>
                            {{adjustmentPercentChange}}%
                        </div>
                        <div class="avo-text-light-small ml-2">Prior month</div>
                    </div>
                    <div v-else class="avo-text-light-small">Same as prior month</div>
                </div-->
                <div class="d-flex flex-column justify-content-center align-items-start">
                    <div class="avo-text-light">Total A/R Balance</div>
                    <div class="total-amount-text">${{parseFloat(netARValue).toLocaleString()}}</div>
                    <div class="d-flex align-items-center" v-if="netARDirection != 'no-change'">
                        <div :class="netARDirection">
                            <b-icon
                                :icon="netARDirection == 'increase' ? 'caret-up-fill' : 'caret-down-fill'"></b-icon>
                            {{netARPercentChange}}%
                        </div>
                        <div class="avo-text-light-small ml-2">Prior month</div>
                    </div>
                    <div v-else class="avo-text-light-small">Same as prior month</div>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-start">
                    <div class="avo-text-light">Total Write-offs</div>
                    <div class="total-amount-text">${{parseFloat(claimStats.write_offs).toLocaleString()}}</div>
                    <div class="d-flex align-items-center" v-if="writeOffsDirection != 'no-change'">
                        <div :class="writeOffsDirection">
                            <b-icon
                                :icon="writeOffsDirection == 'increase' ? 'caret-up-fill' : 'caret-down-fill'"></b-icon>
                            {{writeOffsPercentChange}}%
                        </div>
                        <div class="avo-text-light-small ml-2">Prior month</div>
                    </div>
                    <div v-else class="avo-text-light-small">Same as prior month</div>
                </div>
                <!--div class="d-flex flex-column justify-content-center align-items-start">
                    <div class="avo-text-light">Accounts Receivable</div>
                    <div class="total-amount-text">${{parseFloat(ARValue).toLocaleString()}}</div>
                    <div class="d-flex align-items-center" v-if="ARDirection != 'no-change'">
                        <div :class="ARDirection">
                            <b-icon
                                :icon="ARDirection == 'increase' ? 'caret-up-fill' : 'caret-down-fill'"></b-icon>
                            {{ARPercentChange}}%
                        </div>
                        <div class="avo-text-light-small ml-2">Prior month</div>
                    </div>
                    <div v-else class="avo-text-light-small">Same as prior month</div>
                </div-->
            </b-card>
            </b-overlay>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="8" class="">
            <b-card class='white-card shadow mb-4'>
                <div class="summary-card-header-txt">
                    Collections by Month
                </div>
                <div class="chart-container" style="height:350px">
                    <div v-if="collectionsByMonthLoading" class="spinner-container">
                        <b-spinner></b-spinner>
                    </div>
                    <collections-line-chart :chartData="collectionsByMonthChartData" :styles="{width:'100%', height:'100%', position:'relative'}"></collections-line-chart>
                </div>
            </b-card>
            <b-card class='summary-card shadow mb-4 pr-2'>
                <div class="summary-card-header-txt">
                    Claims by Stage
                </div>
                <div class="chart-container" style="height:400px">
                    <div v-if="claimsByStageChartLoading" class="spinner-container">
                        <b-spinner></b-spinner>
                    </div>
                    <horizontal-bar-chart :chartData="claimsByStageChartData" :styles="{width:'100%', height:'100%', position:'relative'}"></horizontal-bar-chart>
                </div>
            </b-card>
            <b-card class='white-card shadow mb-4'>
                <div class="summary-card-header-txt">
                    AR by Month
                </div>
                <div class="chart-container" style="height:350px">
                    <div v-if="ARByMonthLoading" class="spinner-container">
                        <b-spinner></b-spinner>
                    </div>
                    <ar-line-chart :chartData="ARByMonthChartData" :styles="{width:'100%', height:'100%', position:'relative'}"></ar-line-chart>
                </div>
            </b-card>
            <b-card class='summary-card shadow mb-4 pr-2'>
                <div class="summary-card-header-txt mb-2">
                    Top Providers by Encounters
                </div>
                <div class="chart-container" style="height:350px">
                    <div v-if="encounterChartLoading" class="spinner-container">
                        <b-spinner></b-spinner>
                    </div>
                    <horizontal-bar-chart :chartData="encounterChartData" :styles="{width:'100%', height:'100%', position:'relative'}"></horizontal-bar-chart>
                </div>
            </b-card>
            <b-card class='summary-card shadow mb-4 pr-2'>
                <div class="summary-card-header-txt mb-2">
                    Top Procedures by Count
                </div>
                <div class="chart-container" style="height:350px">
                    <div v-if="procedureChartLoading" class="spinner-container">
                        <b-spinner></b-spinner>
                    </div>
                    <horizontal-bar-chart :chartData="procedureChartData" :styles="{width:'100%', height:'100%', position:'relative'}"></horizontal-bar-chart>
                </div>
            </b-card>
            <b-card class='summary-card shadow mb-4 pb-2' >
                <div class="summary-card-header-txt mb-4">
                    Accounts Receivable by Age
                </div>
                <donut-with-bar :chartData="ARDonutChartData" :dataLoading="ARChartLoading" format='dollars'/>
            </b-card>
        </b-col>
        <b-col cols="4" class="pl-0">
            <b-card class='summary-card shadow mb-4'>
                <div class="summary-card-header-txt">
                    Top Claim Issues
                </div>
                <div class="chart-container" style="height:100px">
                    <div v-if="issuesChartLoading" class="spinner-container">
                        <b-spinner></b-spinner>
                    </div>
                    <donut-chart 
                        :loaded="!issuesChartLoading" 
                        :chartData="issueChartData" 
                        :styles="{width: '100%', height: '100%', position:'relative'}" 
                    />
                </div>
            </b-card>
            <b-card class='summary-card shadow mb-4 pl-3'>
                <div class="d-flex align-items-center">
                    <div class="claims-worked-text mr-5">{{claimStats.claims_worked}}</div>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="avo-medium-text">Claims Worked</div>
                    </div>
                </div>
            </b-card>
            <b-card class='summary-card shadow mb-4 pl-3'>
                <div class="d-flex align-items-center">
                    <div class="days-ar-text mr-5">{{avgDaysInAR}}</div>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="avo-medium-text">Average Days in A/R</div>
                    </div>
                </div>
            </b-card>
            <b-card class='summary-card shadow mb-4 pl-3'>
                <div class="d-flex align-items-center">
                    <div class="first-pass-text mr-5">{{firstPassPercent}}%</div>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="avo-medium-text">First Pass</div>
                    </div>
                </div>
            </b-card>
            <b-card class='summary-card shadow mb-4 pl-3'>
                <div class="d-flex align-items-center">
                    <div class="claims-collected-text mr-5">{{claimsCollectedPercent}}%</div>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="avo-medium-text">Claims Collected</div>
                    </div>
                </div>
            </b-card>
            <b-card class='summary-card shadow mb-4'>
                <div class="d-flex flex-row justify-content-start pb-4">
                    <div class="summary-card-header-txt">
                        Top Providers by Billing
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center small-head-txt-med mb-3">
                    <div>Provider</div>
                    <div>Amount</div>
                </div>
                <div v-if="topProvidersByBilling.length > 0">
                    <div class="d-flex justify-content-between align-items-center mb-3"
                        v-for="(item, index) in topProvidersByBilling" :key="index">
                        <div style="color:#38455D">{{item.full_name_with_suffix}}</div>
                        <div style="color:#67B765;font-weight:500">${{parseFloat(item.count).toLocaleString()}}</div>
                    </div>
                </div>
                <div v-else class="w-100 text-center">No Data</div>
            </b-card>
            <b-card class='summary-card shadow mb-4'>
                <div class="d-flex flex-row justify-content-start pb-4">
                    <div class="summary-card-header-txt">
                        Top Procedures by Billing
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center small-head-txt-med mb-3">
                    <div>Procedure</div>
                    <div>Amount</div>
                </div>
                <div v-if="topProceduresByBilling.length > 0">
                    <div class="d-flex justify-content-between align-items-center mb-3"
                        v-for="(item, index) in topProceduresByBilling" :key="index">
                        <div style="color:#38455D">{{item.short}}</div>
                        <div style="color:#139F92;font-weight:500">${{parseFloat(item.count).toLocaleString()}}</div>
                    </div> 
                </div>
                <div v-else class="w-100 text-center">No Data</div>
            </b-card>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import { make_url, authenticated_request } from '../../common.js'
import HorizontalBarChart from '../charts/HorizontalBarChart.vue'
import DonutChart from '../charts/DonutChart.vue'
import DonutWithBar from '../charts/DonutWithBar.vue'
import CollectionsLineChart from '../charts/CollectionsLineChart.vue'
import ArLineChart from '../charts/ArLineChart.vue'

export default {
    name: 'PracticeSummary',
    components: {
        HorizontalBarChart,
        DonutChart,
        DonutWithBar,
        CollectionsLineChart,
        ArLineChart,
    },
    data(){
        return {
            // Stat box data
            claimStats: {
                adjustments: 0,
                adjustments_last_month: 0,
                closed_claim_count: 0,
                collection: 0,
                collection_last_month: 0,
                gross_charges: 0,
                gross_charges_last_month: 0,
                patient_visits: 0,
                patient_visits_last_month: 0,
                total_claim_count: 0,
                total_days_in_ar: 0,
                total_first_pass: 0,
                write_offs: 0,
                write_offs_last_month: 0,
                claims_worked: 0,
            },

            // table data
            topProvidersByBilling: [],
            topProceduresByBilling: [],

            blackListClaims: {
                ar_total: {
                    count: 0,
                    amount: 0
                },
                ar_assigned: {
                    count: 0,
                    amount: 0
                },
                ar_unassigned: {
                    count: 0,
                    amount: 0
                },
                untouched: {
                    count: 0,
                    amount: 0,
                },
                unresolved: {
                    count: 0,
                    amount: 0
                }
            },

            // chart loading
            encounterChartLoading: true,
            procedureChartLoading: true,
            ARChartLoading: true,
            issuesChartLoading: true,
            claimStatsLoading: true,
            claimsByStageChartLoading: true,
            collectionsByMonthLoading: true,
            ARByMonthLoading: true,

            // chart data
            encounterChartData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: '#7A9CE5',
                        data: []
                    }
                ]
            },
            procedureChartData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: '#FFC9B0',
                        data: []
                    }
                ]
            },
            issueChartData: {
                hoverBorderWidth: 10,
                labels: [],
                datasets: [
                    {
                        label: "Data One",
                        backgroundColor: ["#7A9CE5", "#41B0A5", "#D191DF", "#EEC05A", "#FEA487"],
                        data: []
                    }
                ]
            },
            ARDonutChartData: {
                hoverBorderWidth: 10,
                labels: ["0-30 Days", "31-60 Days", "61-90 Days", "91-120 Days", "121+ Days"],
                datasets: [
                    {
                        label: "Data One",
                        backgroundColor: ["#7A9CE5", "#41B0A5", "#D191DF", "#EEC05A", "#FEA487"],
                        data: []
                    }
                ]
            },
            claimsByStageChartData: {
                labels: ["Unopened Claim", "Open Claim", "Submitted", "Pending", "Rejected/Denied", "AR Review", "Client/Mgmt. Assistance", "Posting", "Patient Balance", "Closed"],
                datasets: [
                    {
                        backgroundColor: '#FEA487',
                        data: []
                    }
                ]
            },
            collectionsByMonthChartData: {
                labels: [],
                datasets: []
            },
            ARByMonthChartData: {
                labels: [],
                datasets: []
            },
            months: ["tilt", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        }
    },
    methods: {
        getClaimStats() {
            const url = make_url("/reports/claims/stats");
            const vm = this;

            const promise = authenticated_request({
                method: "get",
                url: url,
                params: { practice_id: this.practice.practice_id }
            }).then(function(response){
                if (response) {
                    const stats = response.data;
                    console.log(stats)
                    vm.claimStats = stats;
                }
            }).catch(function(error){
                console.log(error.response)
            }).finally(()=>{
                vm.claimStatsLoading = false;
            });
            return promise;
        },
        getTopProviders() {
            const url = make_url("/reports/practices/" + this.practice.practice_id + "/providers");
            const vm = this;

            const promise = authenticated_request({
                method: "get",
                url: url,
            }).then(function(response){
                if (response) {
                    const items = response.data;
                    console.log(items)
                    vm.topProvidersByBilling = items.providers_by_billing;

                    var countArr = [], labelArr = [];
                    items.providers_by_encounter.forEach(function (item) {
                        countArr.push(item.count);
                        labelArr.push(item.full_name_with_suffix)
                    })
                    vm.encounterChartData.labels = labelArr;
                    vm.encounterChartData.datasets[0].data = countArr;
                    vm.encounterChartLoading = false;
                    
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },
        // right now, we are using codes in place of actual procedures (grouping of codes)
        getTopProcedures() {
            const url = make_url("/reports/claims/procedures");
            const vm = this;

            const promise = authenticated_request({
                method: "get",
                url: url,
                params: { practice_id: this.practice.practice_id }
            }).then(function(response){
                if (response) {
                    const items = response.data;
                    console.log(items)
                    vm.topProceduresByBilling = items.procedures_by_billing;

                    var countArr = [], labelArr = [];
                    items.procedures_by_encounter.forEach(function (item) {
                        countArr.push(item.count);
                        labelArr.push(item.short)
                    })
                    vm.procedureChartData.labels = labelArr;
                    vm.procedureChartData.datasets[0].data = countArr;
                    vm.procedureChartLoading = false;
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },
        getTopClaimIssues() {
            const url = make_url("/reports/claims/issues");
            const vm = this;

            const promise = authenticated_request({
                method: "get",
                url: url,
                params: { practice_id: this.practice.practice_id }
            }).then(function(response){
                if (response) {
                    const issues = response.data;
                    vm.issueChartData.labels = Object.keys(issues);
                    vm.issueChartData.datasets[0].data = Object.values(issues);
                    vm.issuesChartLoading = false;
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },

        getARByAge() {
            const url = make_url("/reports/claims/accounts_receivable");
            const vm = this;

            const promise = authenticated_request({
                method: "get",
                url: url,
                params: { practice_id: this.practice.practice_id }
            }).then(function(response){
                if (response) {
                    const items = response.data;
                    // console.log(items);
                    var arByAgeList = [];
                    arByAgeList.push(parseFloat(items.accounts_receivable_0_30_days));
                    arByAgeList.push(parseFloat(items.accounts_receivable_31_60_days));
                    arByAgeList.push(parseFloat(items.accounts_receivable_61_90_days));
                    arByAgeList.push(parseFloat(items.accounts_receivable_91_120_days));
                    arByAgeList.push(parseFloat(items.accounts_receivable_121_days));
                    vm.ARDonutChartData.datasets[0].data = arByAgeList;
                    vm.ARChartLoading = false;

                    vm.blackListClaims.ar_total.count = items.claim_count_total, vm.blackListClaims.ar_total.amount = parseFloat(items.accounts_receivable_total);
                    vm.blackListClaims.ar_assigned.count = items.claim_count_assigned, vm.blackListClaims.ar_assigned.amount = parseFloat(items.accounts_receivable_assigned);
                    vm.blackListClaims.ar_unassigned.count = items.claim_count_unassigned, vm.blackListClaims.ar_unassigned.amount = parseFloat(items.accounts_receivable_unassigned);
                    vm.blackListClaims.untouched.count = items.claim_count_untouched, vm.blackListClaims.untouched.amount = parseFloat(items.accounts_receivable_untouched);
                    vm.blackListClaims.unresolved.count = items.claim_count_unresolved, vm.blackListClaims.unresolved.amount = parseFloat(items.accounts_receivable_unresolved);                    
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },
        getClaimsByStage(){
            const url = make_url("/reports/claims/state");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: {
                    practice_ids: this.practice.practice_id
                }
            }).then(function(response){
                if (response) {
                    const items = response.data;
                    vm.claimsByStageChartData = {
                        labels: ["Unopened Claim", "Open Claim", "Submitted", "Pending", "Rejected/Denied", "AR Review", "Client/Mgmt. Assistance", "Posting", "Patient Balance", "Closed"],
                        datasets: [
                            {
                                backgroundColor: '#FEA487',
                                data: [
                                    items.unopened || 0,
                                    items.opened || 0,
                                    items.submitted || 0,
                                    items.pending || 0,
                                    items.rejected_denied || 0,
                                    items.ar_review || 0,
                                    items.assistance || 0,
                                    items.posting || 0,
                                    items.patient_balance || 0,
                                    items.closed || 0
                                ]
                            }
                        ]
                    };
                    vm.claimsByStageChartLoading = false;              
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },
        getCollectionByMonth() {
            const url = make_url("/reports/claims/collections");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: {
                    practice_ids: this.practice.practice_id
                }
            }).then(function(response){
                if (response) {
                    const collections = response.data;
                    var labels = [];
                    var data = [];
                    //var lineColors = ["#37D5D9", "#EEC05A", "#7550CB", "#FE816B", "#67B765", "#FE679F"]
                    //var tempArr = []
                    collections.forEach(function (collection) {
                        labels.push(vm.months[collection.month] + " " + collection.year.toString());
                        data.push(collection.collections);
                    });
                    vm.collectionsByMonthChartData = {
                        labels: labels,
                        datasets: [
                            {
                                label: "Collections",
                                data: data,
                                backgroundColor: "transparent",
                                borderColor: "#37D5D9"
                            }
                        ]
                    };
                    vm.collectionsByMonthLoading = false
                }
            }).catch(function(error){
                console.log(error)
            });
            return promise;
        },
        getARByMonth() {
            const url = make_url("/reports/claims/payers/ar");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: {
                    practice_ids: this.practice.practice_id
                }
            }).then(function(response){
                if (response) {
                    const ar_data = response.data.filter((item) => {
                        // TODO: We should probably be doing this on the backend
                        return item.month != null && item.year != null;
                    });
                    var labels = [];
                    var payers = {};
                    var date_str;
                    var ar_data_dates = ar_data.map(obj => (vm.months[obj.month] + ' ' + obj.year));
                    for (var i = 0; i < ar_data_dates.length; i++){
                        if (!labels.includes(ar_data_dates[i])){
                            labels.push(ar_data_dates[i]);
                        }
                    }  
                    ar_data.forEach(function (ar) {
                        date_str = vm.months[ar.month] + ' ' + ar.year;
                        if (!payers[ar.name]){
                            payers[ar.name] = new Array(labels.length).fill(0);
                        }
                        payers[ar.name][labels.indexOf(date_str)] = ar.ar;
                    });
                    var lineColors = ["#37D5D9", "#EEC05A", "#7550CB", "#FE816B", "#67B765", "#FE679F"]
                    var tempArr = [];
                    i = 0;
                    for (const [key, value] of Object.entries(payers)) {
                        tempArr.push(
                            {
                                label: key,
                                data: value,
                                backgroundColor: "transparent",
                                // cycles through line color array
                                borderColor: lineColors[ i % lineColors.length ]
                            }
                        )    
                        i = i + 1;                    
                    }
                    vm.ARByMonthChartData = {
                        labels: labels,
                        datasets: tempArr
                    };
                    vm.ARByMonthLoading = false;
                }
            }).catch(function(error){
                console.log(error)
            });
            return promise;
        },
    },
    computed: {
        practice: function(){
            return this.$store.state.practices.selected_practice;
        },

        // for claim stats (top bar)
        patientVisitDirection: function(){
            return this.claimStats.patient_visits > this.claimStats.patient_visits_last_month ? 'increase' : this.claimStats.patient_visits < this.claimStats.patient_visits_last_month ? 'decrease' : 'no-change';
        },
        visitPercentChange: function(){
            return Math.abs(((this.claimStats.patient_visits - this.claimStats.patient_visits_last_month)/this.claimStats.patient_visits_last_month*100.0).toFixed(1));
        },
        grossChargeDirection: function(){
            return this.claimStats.gross_charges > this.claimStats.gross_charges_last_month ? 'increase' : this.claimStats.gross_charges < this.claimStats.gross_charges_last_month ? 'decrease' : 'no-change';
        },
        grossPercentChange: function(){
            return  Math.abs(((this.claimStats.gross_charges - this.claimStats.gross_charges_last_month)/this.claimStats.gross_charges_last_month*100.0).toFixed(1));
        },
        collectionDirection: function(){
            return this.claimStats.collection > this.claimStats.collection_last_month ? 'increase' : this.claimStats.collection < this.claimStats.collection_last_month ? 'decrease' : 'no-change';
        },
        collectionPercentChange: function(){
            return  Math.abs(((this.claimStats.collection - this.claimStats.collection_last_month)/this.claimStats.collection_last_month*100.0).toFixed(1));
        },
        adjustmentDirection: function(){
            return this.claimStats.adjustments > this.claimStats.adjustments_last_month ? 'increase' : this.claimStats.adjustments < this.claimStats.adjustments_last_month ? 'decrease' : 'no-change';
        },
        adjustmentPercentChange: function(){
            return  Math.abs(((this.claimStats.adjustments - this.claimStats.adjustments_last_month)/this.claimStats.adjustments_last_month*100.0).toFixed(1));
        },
        writeOffsDirection: function(){
            return this.claimStats.write_offs > this.claimStats.write_offs_last_month ? 'increase' : this.claimStats.write_offs < this.claimStats.write_offs_last_month ? 'decrease' : 'no-change';
        },
        writeOffsPercentChange: function(){
            return  Math.abs(((this.claimStats.write_offs - this.claimStats.write_offs_last_month)/this.claimStats.write_offs_last_month*100.0).toFixed(1));
        },
        ARValue: function() {
            return parseFloat(this.claimStats.gross_charges) - parseFloat(this.claimStats.collection);
        },
        ARValueLastMonth: function() {
            return parseFloat(this.claimStats.gross_charges_last_month) - parseFloat(this.claimStats.collection_last_month);
        },
        ARDirection: function(){
            return this.ARValue > this.ARValueLastMonth ? 'increase' : this.ARValue < this.ARValueLastMonth ? 'decrease' : 'no-change';
        },
        ARPercentChange: function(){
            return  Math.abs(((this.ARValue - this.ARValueLastMonth)/this.ARValueLastMonth*100.0).toFixed(1));
        },
        netARValue: function() {
            return (parseFloat(this.practice.net_receivables) * this.ARValue).toFixed(2);
        },
        netARValueLastMonth: function() {
            return (parseFloat(this.practice.net_receivables) * this.ARValueLastMonth).toFixed(2);
        },
        netARDirection: function(){
            return this.netARValue > this.netARValueLastMonth ? 'increase' : this.netARValue < this.netARValueLastMonth ? 'decrease' : 'no-change';
        },
        netARPercentChange: function(){
            return  Math.abs(((this.netARValue - this.netARValueLastMonth)/this.netARValueLastMonth*100.0).toFixed(1));
        },

        // for claim stats (right bar)
        avgDaysInAR: function() {
            if (this.claimStats.closed_claim_count == 0){
                return 0
            }
            return (this.claimStats.total_days_in_ar / this.claimStats.closed_claim_count).toFixed(0);
        },
        firstPassPercent: function() {
            if (this.claimStats.closed_claim_count == 0){
                return 0
            }
            return ((this.claimStats.total_first_pass / this.claimStats.closed_claim_count)*100.0).toFixed(0);
        },
        claimsCollectedPercent: function() {
            if (this.claimStats.total_claim_count == 0){
                return 0
            }
            return ((this.claimStats.closed_claim_count / this.claimStats.total_claim_count)*100.0).toFixed(0);
        },
        
        
    },
    async mounted() {
        this.getClaimStats();
        this.getTopProviders();
        this.getTopProcedures();
        this.getTopClaimIssues();
        this.getARByAge();
        this.getClaimsByStage();
        this.getCollectionByMonth();
        this.getARByMonth();
    }
}
</script>

<style scoped>
/* increasing b-containers max-width for larger screens */
@media only screen and (min-width : 1200px) {
   .container { max-width: 1400px; } 
} 
/* styles for the top stat banner */
.avo-info-card .card-body{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.increase {
    color: #67B765;
    font-size: 14px;
    font-weight: 500;
}
.decrease {
    color: var(--red);
    font-size: 14px;
    font-weight: 500;
}
.no-change {
    color: grey;
    font-size: 14px;
    font-weight: 500;
}
/* styles for summary cards */
.summary-card {
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #E2E4EB;
}
.chart-container {
    position:relative;
    display: flex; 
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100px;
    /* height: 300px; */
    overflow:visible !important;
    /* padding: 16px 16px 24px 0; */
}
.spinner-container {
    position: absolute;
    top:50%;
    left:45%
}
.absolute-centering {
    position: absolute;
    /* move the element half way across the screen */
    left: 50%;
    /* allow the width to be calculated dynamically */
    width: auto;
    /* then move the element back again using a transform */
    transform: translateX(-50%);
}

/* various text styles */
.avo-medium-text {
    font-weight: 500;
    font-size: 16px;
    color: #38455D
}
.small-head-txt {
    font-size:14px;
    color:#38455D;
}
.small-head-txt-med {
    font-weight:500;
    font-size:14px;
    color:#38455D;
}
.small-head-txt-bold {
    font-weight:600;
    font-size:14px;
    color:#38455D;
}
.claims-worked-text{
    font-size: 32px;
    font-weight: 600;
    color: #D191DF;
}
.days-ar-text {
    font-size: 32px;
    font-weight: 600;
    color: var(--blue);
}
.first-pass-text {
    font-size: 32px;
    font-weight: 600;
    color: #67B765;
}
.claims-collected-text {
    font-size: 32px;
    font-weight: 600;
    color: #FE816B;
}

/* styles for custom AR By Age progress bar-type graph */
.thin-bar-background {
    background-color:#E7EAEE;
    height:7px;
    border-radius:4px;
}
.thin-bar-overlay {
    height:7px;
    border-radius:4px;
    margin-top:-7px;
}
</style>