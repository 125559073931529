<template>
<div id="update-practice-master-pass">
<b-modal id="update-practice-master-pass" @hide="resetModal" title="Manage Practice Master Pass" hide-footer size="lg">
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <b-row>
        <b-col>
            <b-form-group
            id="password"
            label="Password">
            <b-input-group>
                <b-form-input type="password" autocomplete="new-password" v-model="form.password"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="confirmpassword"
            label="Confirm password">
            <b-input-group>
                <b-form-input type="password" v-model="form.confirm_password"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col style="text-align:center">
            <b-button @click="onSubmit" class="avo-primary-btn">
                Update Master Password
            </b-button>
        </b-col>
    </b-row>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'

export default {
  name: 'PracticeMasterPasswordEdit',
  mixins: [alertsMixin],
  data: function(){
      return {
            form: {
                password: null,
                confirm_password: null,
            },
            passwords: ["password", "confirm_password"],
      }
  },
  methods: {
    clearData(){
        this.form = {
            password: null,
            confirm_password: null,
        };
        this.hideError();
    },
    validateInputs(){
        this.hideError();
        this.resource = {};
        
        //validate inputs
        for (var i = 0; i < this.passwords.length; i++){
            if ((this.form[this.passwords[i]] == null) || (this.form[this.passwords[i]] == "")){
                this.showError("Missing field: " + this.passwords[i].replace("_", " "));
                return false;
            }
        }
        if (this.form.password != this.form.confirm_password){
            this.showError("Passwords do not match");
            return false;
        }
        
        return true
    },
    setPracticeMasterPassword(){
        const url = make_url("/practices/" + this.practice_id + "/resources/masterpassword");
        const self = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
            data: { password: this.form.password },
        }).then(function(response){
            if (response){
                self.hideError();
                self.$bvModal.hide('update-practice-master-pass');
                self.$emit('submitted');
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    onSubmit(){
        if (this.validateInputs()){
            this.setPracticeMasterPassword();
        }
    },
    resetModal(){
        this.hideError();
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
    computed: {
        practice_id: function(){
            return this.$store.state.practices.selected_practice.practice_id;
        },
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
</style>