<template>
<div id="provider-edit">
<b-modal :id="modal_id" title="Edit Provider" hide-footer size="xl">
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
    <b-row>
        <b-col>
            <b-form-group
            id="given_name"
            label="First name">
            <b-input-group>
                <b-form-input v-model="form.given_name" :placeholder="selectedProvider.given_name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="middle_name_or_initial"
            label="Middle name">
            <b-input-group>
                <b-form-input v-model="form.middle_name_or_initial" :placeholder="selectedProvider.middle_name_or_initial"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="family_name"
            label="Last name">
            <b-input-group>
                <b-form-input v-model="form.family_name" :placeholder="selectedProvider.family_name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="suffix"
            label="Suffix">
            <b-input-group>
                <b-form-input v-model="form.suffix" :placeholder="selectedProvider.suffix"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="ssn"
            label="SSN">
            <b-input-group>
                <b-form-input v-model="form.ssn" :placeholder="selectedProvider.ssn"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="ptan"
            label="PTAN">
            <b-input-group>
                <b-form-input v-model="form.ptan" :placeholder="selectedProvider.ptan"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="npi"
            label="NPI">
            <b-input-group>
                <b-form-input v-model="form.npi" :placeholder="selectedProvider.npi"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
        <b-form-group
            id="provider_type"
            label="Billing type">
            <b-form-select v-model="form.provider_type" :options="types">
                <template #first>
                <b-form-select-option :value="null" disabled>
                    Select type
                </b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="email"
            label="Email">
            <b-input-group>
                <b-form-input 
                    v-model="form.email"
                    type="email"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
                id="date-of-birth"
                label="Date of birth"
                style="text-align:left">
            <b-form-input type="date" 
                v-model="form.date_of_birth"
                class="mb-4"
                reset-button
                close-button></b-form-input>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
                id="start-date"
                label="Start date"
                style="text-align:left">
            <b-form-input type="date" 
                v-model="form.start_date" 
                class="mb-4"
                today-button
                reset-button
                close-button></b-form-input>
            </b-form-group>
        </b-col>
    </b-row>
    </div>
    <b-row>
        <b-col style="text-align:right">
            <b-button @click="onSubmit" class="avo-primary-btn">
                    Make changes to provider data
            </b-button>
        </b-col>
    </b-row>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'

export default {
  name: 'ProviderEdit',
  // TODO: Need to set up initial values for start_date, provider_type, etc
  // [AV-502] TODO: Need to add taxonomy code field
  props: ['selectedProvider'],
  mixins: [alertsMixin],
  data: function(){
      return {
            form: {
                given_name: null,
                middle_name_or_initial: null,
                family_name: null,
                suffix: null,
                date_of_birth: null,
                ssn: null,
                npi: null,
                ptan: null,
                email: null,
                provider_type: null,
                start_date: null,
            },
            provider: {},
      }
  },
  methods: {
    clearData(){
        this.form = {
            given_name: null,
            middle_name_or_initial: null,
            family_name: null,
            suffix: null,
            date_of_birth: null,
            ssn: null,
            npi: null,
            ptan: null,
            email: null,
            provider_type: null,
            start_date: null,
        }
        this.provider = {};
        this.setShowAlert(false);
    },
    validateInputs(){
        this.setShowAlert(false);
        
        const fields = ['given_name',
                        'family_name',
                        'middle_name_or_initial', 
                        'suffix', 
                        'date_of_birth', 
                        'ssn', 
                        'npi', 
                        'ptan',
                        'email',
                        'provider_type', 
                        'start_date',
        ]
        
        for (var i = 0; i < fields.length; i++){
            if ((this.form[fields[i]] != null) && (this.form[fields[i]] != "")){
                this.provider[fields[i]] = this.form[fields[i]];
            }
        }
        
        return true
    },
    editProvider(){
        const url = make_url("/providers/" + this.provider_id);
        const self = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
            data: this.provider
        }).then(function(response){
            if (response){
                self.$emit('submitted', self.modal_id);
            }
        }).catch(function(error){
            self.setShowAlert(true, error);
        });
        return promise;
    },
    onSubmit(){
        if (this.validateInputs()){
            this.editProvider();
        }
    },
  },
  computed: {
        types: function(){
            return this.$store.state.practices.providersTypes;
        },
        provider_id: function(){
            return this.selectedProvider.provider_id;
        },

        modal_id: function(){
            return 'provider-edit-' + this.provider_id;
        },
  },
  mounted(){
        if (this.types == null) {
            this.$store.dispatch("practices/fetchProvidersTypes");
        }
  },
}
</script>

<style scoped>
</style>